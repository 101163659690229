import {Component, NgZone, OnInit} from '@angular/core';
import {FamiliesService} from '../../../core/services/families.service';
import {AppComponent} from '../../../app.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../core/services/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GetFeesRequest} from '../../../core/interfaces/requests/GetFeesRequest';
import {GetFeesResponse} from '../../../core/interfaces/responses/GetFeesResponse';
import {Fee} from '../../../core/interfaces/models/Fee';
import {Payment} from '../../../core/interfaces/models/Payment';
import {GetPaymentsResponse} from '../../../core/interfaces/responses/GetPaymentsResponse';
import {GetBillsResponse} from '../../../core/interfaces/responses/GetBillsResponse';
import {Bill} from '../../../core/interfaces/models/Bill';
import {GetByIdRequest} from '../../../core/interfaces/requests/GetByIdRequest';
import {DateTimeHelper} from '../../../helpers/date-time-helper';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {AddBillRequest} from '../../../core/interfaces/requests/AddBillRequest';
import {AddBillPayment} from '../../../core/interfaces/models/AddBillPayment';

@Component({
  selector: 'app-families',
  templateUrl: './family-payments.component.html',
  styleUrls: ['./family-payments.component.scss']
})

export class FamilyPaymentsComponent implements OnInit {

  public fees: Array<Fee> = new Array<Fee>();
  public payments: Array<Payment> = new Array<Payment>();
  public pendingBills: Array<Bill> = Array<Bill>();
  public completedBills: Array<Bill> = Array<Bill>();
  public newBillPayments: Array<Payment> = new Array<Payment>();
  private familyId: number;
  private unsentOpened: boolean;
  private pendingOpened: boolean;
  private completedOpened: boolean;
  public familyName: string;
  protected dateTimeHelper: DateTimeHelper;
  public showAdjustmentsFlag: boolean;
  private billStartDate: Date;
  private billEndDate: Date;

  customPaymentDescription?: string;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private familiesService: FamiliesService,
              private router: Router,
              private auth: AuthService,
              private app: AppComponent,
              private modalService: NgbModal,
              private route: ActivatedRoute) {
    this.dateTimeHelper = new DateTimeHelper();
    const date = new Date();
    this.billStartDate = date;
    this.billEndDate = date;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.familyId = params['id'];
      this.familyName = params['name'];
      this.getPaymentsForFamily(this.familyId);
      this.getBillsForFamily(this.familyId);
    });
    this.getFeesForFranchise();
    this.unsentOpened = true;
    this.pendingOpened = false;
    this.completedOpened = false;
    this.showAdjustmentsFlag = false;
  }

  /**
   * Marker: Get Fees
   * =============================================================================================
   */

  private getFeesForFranchise() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetFeesRequest = {franchiseCity: this.auth.getFranchiseCity()};

    this.familiesService.getFeesForFranchise(params).subscribe(
      (response: GetFeesResponse) => {
        console.log('fees', response);
        this.onGetFeesForFranchiseSuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onGetFeesForFranchiseFailed(error);
      }
    );
  }

  private onGetFeesForFranchiseSuccess(response: GetFeesResponse) {
    if (response.success) {
      this.fees = response.fees;
      this.fees.push({
        feeId: -1,
        feeType: 'Custom Fee',
        feeAmount: 0
      });
    }
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetFeesForFranchiseFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Get Payments
   * =============================================================================================
   */

  private getPaymentsForFamily(familyId: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: familyId};

    this.familiesService.getPaymentsForFamily(params).subscribe(
      (response: GetPaymentsResponse) => {
        console.log('payments', response);
        this.onGetPaymentsForFamilySuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onGetPaymentsForFamilyFailed(error);
      }
    );
  }

  private onGetPaymentsForFamilySuccess(response: GetPaymentsResponse) {
    if (response.success) {
      this.payments = response.payments;
    }
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetPaymentsForFamilyFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Get Bills
   * =============================================================================================
   */

  private getBillsForFamily(familyId: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: familyId};

    this.familiesService.getBillsForFamily(params).subscribe(
      (response: GetBillsResponse) => {
        console.log('bills', response);
        this.onGetBillsForFamilySuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onGetBillsForFamilyFailed(error);
      }
    );
  }

  private onGetBillsForFamilySuccess(response: GetBillsResponse) {
    if (response.success) {
      this.pendingBills = response.pendingBills;
      this.completedBills = response.completedBills;
    }
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetBillsForFamilyFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Pay Bill
   * =============================================================================================
   */

  private payBill() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: AddBillRequest = {
      startDate: this.billStartDate,
      endDate: this.billEndDate,
      clientNumber: 'Client Number',
      generationDate: new Date(),
      billPayments: this.getBillPayments()
    };
    console.log(params);
    this.familiesService.addBill(params).subscribe(
      (response: BaseResponse) => {
        console.log(response);
        this.onPayBillSuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onPayBillFailed(error);
      }
    );
  }

  private onPayBillSuccess(response: BaseResponse) {
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    this.getPaymentsForFamily(this.familyId);
    this.getBillsForFamily(this.familyId);
    this.unsentOpened = true;
    this.pendingOpened = false;
    this.completedOpened = false;
    this.showAdjustmentsFlag = false;
    this.billEndDate = new Date();
    this.newBillPayments = new Array<Payment>();
  }

  private onPayBillFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  public getBillTotal() {
    let total = 0;
    for (let i = 0; i < this.newBillPayments.length; i++) {
      for (let j = 0; j < this.newBillPayments[i].jobFees.length; j++) {
        total += this.newBillPayments[i].jobFees[j].feeAmount;
      }
    }
    return total;
  }

  private getBillPayments(): Array<AddBillPayment> {
    const billPayments: Array<AddBillPayment> = new Array<AddBillPayment>();
    for (let i = 0; i < this.newBillPayments.length; i++) {
      const idArray = [];
      for (let j = 0; j < this.newBillPayments[i].jobFees.length; j++) {
        if (this.newBillPayments[i].jobFees[j].feeId !== -1) {
          idArray.push(this.newBillPayments[i].jobFees[j].feeId);
        }
      }
      billPayments.push({
        jobId: this.newBillPayments[i].jobId,
        appliedStandardFees: idArray,
        customPaymentAmount: (this.newBillPayments[i].customPaymentAmount !== null) ? this.newBillPayments[i].customPaymentAmount : 0,
        customPaymentDescription: (this.newBillPayments[i].customPaymentDescription !== null) ? this.newBillPayments[i].customPaymentDescription : ''
      });
    }
    return billPayments;
  }

  indexTracker(index: number, value: any) {
    return index;
  }

  // add or remove from fees array in the selected payment
  public addToFees(fee: Fee, indexPaymet: number) {
    this.payments[indexPaymet].jobFees.push(fee);
  }

  public removeFromFees(indexFee: number, indexPayment: number) {
    this.payments[indexPayment].jobFees.splice(indexFee, 1);
  }

  // check if fee is already added on the selected payment
  public checkIfFeeSelected(indexPayment: number, feeId: number) {
    for (let i = 0; i < this.payments[indexPayment].jobFees.length; i++) {
      if (this.payments[indexPayment].jobFees[i].feeId === feeId) {
        return false;
      }
    }
    return true;
  }

  // add or remove payment from new bill based on the checkbox value

  public checkIfPaymentSelected(indexPayment: number, jobId: number) {
    let flag = -1;
    for (let i = 0; i < this.newBillPayments.length; i++) {
      if (this.newBillPayments[i].jobId === jobId) {
        flag = i;
      }
    }
    if (flag !== -1) {
      this.removePaymentFromBill(flag);
    } else {
      this.addToBill(indexPayment);
    }
  }

  public removePaymentFromBill(indexPaymentNewBill: number) {
    this.newBillPayments.splice(indexPaymentNewBill, 1);
  }

  public addToBill(indexPayment: number) {
    this.newBillPayments.push(this.payments[indexPayment]);
  }

  public getPaymentTotal(payment: Payment) {
    let total = 0;
    for (let i = 0; i < payment.jobFees.length; i++) {
      if (payment.jobFees[i].feeId === -1 && payment.customPaymentAmount !== null) {
        payment.jobFees[i].feeAmount = Number.parseInt(payment.customPaymentAmount.toString());
        total += Number.parseInt(payment.customPaymentAmount.toString());
      } else {
        total += payment.jobFees[i].feeAmount;
      }
    }
    return total;
  }

  // sort all payments by start date
  protected sortTimeRanges() {

    this.newBillPayments = this.newBillPayments.sort((previous, current) => {

      // get the start date from previous and current
      const previousYear = new Date(previous.jobDate).getFullYear();
      const previousMonth = new Date(previous.jobDate).getMonth();
      const previousDay = new Date(previous.jobDate).getDate();
      const currentYear = new Date(current.jobDate).getFullYear();
      const currentMonth = new Date(current.jobDate).getMonth();
      const currentDay = new Date(current.jobDate).getDate();

      // if the previous is earlier than the current
      if (previousYear < currentYear || (previousYear === currentYear && previousMonth < currentMonth) || (previousYear === currentYear && previousMonth === currentMonth && previousDay < currentDay)) {
        return -1;
      }

      // if the previous time is the same as the current time
      if (previousYear === currentYear && previousMonth === currentMonth && previousDay === currentDay) {
        return 0;
      }

      // if the previous time is later than the current time
      return 1;
    });
  }

  public getBillingPeriod() {
    let billingPeriod = '';
    const date = new Date();
    const numberOfDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    if (this.newBillPayments.length === 0) {
      // no bills so take two weeks
      if (date.getDate() <= numberOfDays / 2) {
        // first two weeks
        billingPeriod = this.dateTimeHelper.getMonthName(date.getTime()) + '1 - ' + this.dateTimeHelper.getMonthName(date.getTime()) + (Math.round(numberOfDays / 2) - 1) + ', ' + date.getFullYear();
        this.billStartDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + 1);
        this.billEndDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + (Math.round(numberOfDays / 2) - 1));
      } else {
        // last two weeks
        billingPeriod = this.dateTimeHelper.getMonthName(date.getTime()) + Math.round(numberOfDays / 2) + ' - ' + this.dateTimeHelper.getMonthName(date.getTime()) + numberOfDays + ', ' + date.getFullYear();
        this.billStartDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + Math.round(numberOfDays / 2));
        this.billEndDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + numberOfDays);
      }
    } else {
      // sort payments
      this.sortTimeRanges();
      const jobDateStart = new Date(this.newBillPayments[0].jobDate);
      const jobDateEnd = new Date(this.newBillPayments[this.newBillPayments.length - 1].jobDate);
      this.billStartDate = jobDateStart;
      this.billEndDate = jobDateEnd;
      billingPeriod = this.dateTimeHelper.getMonthName(jobDateStart.getTime()) + jobDateStart.getDate() + ' - ' + this.dateTimeHelper.getMonthName(jobDateEnd.getTime()) + jobDateEnd.getDate() + ', ' + jobDateEnd.getFullYear();
    }
    return billingPeriod;
  }

  public getBillingPeriodForBill(startDate: Date, endDate: Date) {

  }

  public remindFamily(billId: number) {

  }

  public changeArrowPosition(arrowId: string) {
    document.getElementById(arrowId).classList.toggle('switch-arrow');
  }

  public menuChanged(menu: string) {
    switch (menu) {
      case 'unsent':
        this.unsentOpened = !this.unsentOpened;
        this.pendingOpened = (this.pendingOpened) ? !this.pendingOpened : this.pendingOpened;
        this.completedOpened = (this.completedOpened) ? !this.completedOpened : this.completedOpened;
        break;
      case 'pending':
        this.pendingOpened = !this.pendingOpened;
        this.unsentOpened = (this.unsentOpened) ? !this.unsentOpened : this.unsentOpened;
        this.completedOpened = (this.completedOpened) ? !this.completedOpened : this.completedOpened;
        break;
      case 'completed':
        this.completedOpened = !this.completedOpened;
        this.unsentOpened = (this.unsentOpened) ? !this.unsentOpened : this.unsentOpened;
        this.pendingOpened = (this.pendingOpened) ? !this.pendingOpened : this.pendingOpened;
        break;
    }
  }
}
