import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SittersService} from '../../../core/services/sitters.service';
import {nameValidator, passwordValidator, phoneValidator, emailValidator} from '../../../helpers/regex';
import {checkMatchingPasswords, noWhitespaceValidator} from '../../../core/shared/utils';
import {ERROR_MESSAGES} from '../../../helpers/errors';
import {GetSittersResponse} from '../../../core/interfaces/responses/GetSittersResponse';
import {AddSitterRequest} from '../../../core/interfaces/requests/AddSitterRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {HttpErrorResponse} from '@angular/common/http';
import {FamiliesService} from '../../../core/services/families.service';
import {AddFamilyRequest} from '../../../core/interfaces/requests/AddFamilyRequest';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {


  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor() {
  }

  ngOnInit() {
  }

  protected scrollToFamilyEmailField(event) {

    document.getElementById('form-group-family-email').scrollIntoView();
  }

  protected scrollToSitterEmailField(event) {

    document.getElementById('form-group-sitter-email').scrollIntoView();
  }

  protected openFamiliesToBill() {

  }

}
