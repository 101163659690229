///<reference path="../../../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import {
  Component,
  Input, OnChanges,
  OnInit, SimpleChanges
} from '@angular/core';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import * as $ from 'jquery';
import {DateTimeHelper} from '../../../helpers/date-time-helper';
import {GetAllJobsResponse} from '../../../core/interfaces/responses/GetAllJobsResponse';
import {GetAllJobsRequest} from '../../../core/interfaces/requests/GetAllJobsRequest';
import {HttpErrorResponse} from '@angular/common/http';
import {DashboardService} from '../../../core/services/dashboard.service';
import * as moment from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';

declare var jQuery: any;

@Component({
  selector: 'app-dashboard-day-calendar',
  templateUrl: './dashboard-day-calendar.component.html',
  styleUrls: ['./dashboard-day-calendar.component.scss']
})

export class DashboardDayCalendarComponent implements OnInit, OnChanges {
  @Input() job: JobInfo;
  protected dateTimeHelper: DateTimeHelper;
  protected allJobs: GetAllJobsResponse;
  protected selectedDay: Date;
  protected eventList: Array<object>;
  protected filterDayEventOptions: Array<string>;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private dashboardService: DashboardService, private modalService: NgbModal) {
    this.dateTimeHelper = new DateTimeHelper();
    this.eventList = [];
    this.filterDayEventOptions = ['upcomingEvent', 'unassignedEvent', 'pendingEvent', 'declinedEvent', 'cancelledEvent', 'completedEvent'];
  }

  ngOnInit() {
    $('#calendar').fullCalendar({
      editable: false,
      eventLimit: false,
      defaultView: 'agendaDay',
      header: {
        left: '',
        center: '',
        right: ''
      },
      selectable: false,
      events: [],
      contentHeight: 'auto'

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.job.currentValue) {
      const date = new Date(changes.job.currentValue.date);
      this.selectedDay = new Date(date.valueOf() + date.getTimezoneOffset() * 60000);
      this.getAllDayJobs();
    }
  }

  /**
   * Marker: Get All Jobs
   * =============================================================================================
   */

  private getAllDayJobs() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const jobDate = this.dateTimeHelper.getBaseDate(this.selectedDay);
    const params: GetAllJobsRequest = {
      calendarType: 1,
      dayAsDate: new Date(jobDate)
    };
    this.dashboardService.getAllJobs(params)
      .subscribe((response: GetAllJobsResponse) => {
          this.onGetAllJobsSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetAllJobsFailed(error);
        });
  }

  private onGetAllJobsSuccess(response: GetAllJobsResponse) {
    this.allJobs = response;
    this.initDayCalendar(this.allJobs);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetAllJobsFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  private filterCalendarEvent() {
    const selectedOption = $('#filterDayCalendarEvent').val();
    if (selectedOption === 'all') {
      for (let i = 0; i < this.filterDayEventOptions.length; i++) {
        $('.' + this.filterDayEventOptions[i]).show();
      }
    } else {
      for (let i = 0; i < this.filterDayEventOptions.length; i++) {
        if (selectedOption === this.filterDayEventOptions[i]) {
          $('.' + this.filterDayEventOptions[i]).show();
        } else {
          $('.' + this.filterDayEventOptions[i]).hide();
        }
      }
    }
  }

  private constructEventList(sourceArray: Array<JobInfo>, className: string) {
    const baseDate = this.dateTimeHelper.getBaseDate(this.selectedDay);

    let color = '';
    switch (className) {
      case 'upcomingEvent':
        color = '#A8C178';
        break;
      case 'unassignedEvent':
        color = '#F2A957';
        break;
      case 'pendingEvent':
        color = '#C6ABC4';
        break;
      case 'declinedEvent':
        color = '#E5A097';
        break;
      case 'cancelledEvent':
        color = '#859EA4';
        break;
      case 'completedEvent':
        color = '#B9DBE6';
        break;
    }
    for (let i = 0; i < sourceArray.length; i++) {
      const startTimeParsed = this.dateTimeHelper.timeConvertor(sourceArray[i].startTime);
      const endTimeParsed = this.dateTimeHelper.timeConvertor(sourceArray[i].endTime);
      this.eventList.push({
        title: sourceArray[i].familyName,
        start: baseDate + 'T' + ((startTimeParsed[0] < 10) ? '0' + startTimeParsed[0] : startTimeParsed[0]) + ':' + ((startTimeParsed[1] < 10) ? '0' + startTimeParsed[1] : startTimeParsed[1]) + ':00',
        end: baseDate + 'T' + ((endTimeParsed[0] < 10) ? '0' + endTimeParsed[0] : endTimeParsed[0]) + ':' + ((endTimeParsed[1] < 10) ? '0' + endTimeParsed[1] : endTimeParsed[1]) + ':00',
        backgroundColor: color,
        borderColor: '#FFFFFF',
        textColor: '#FFFFFF',
        timeFormat: 'h:mm',
        className: className
      });
    }
  }

  initDayCalendar(allJobs: GetAllJobsResponse) {
    this.eventList = [];
    this.constructEventList(allJobs.upcomingJobs, 'upcomingEvent');
    this.constructEventList(allJobs.unassignedJobs, 'unassignedEvent');
    this.constructEventList(allJobs.pendingJobs, 'pendingEvent');
    this.constructEventList(allJobs.declinedJobs, 'declinedEvent');
    this.constructEventList(allJobs.canceledJobs, 'cancelledEvent');
    this.constructEventList(allJobs.completedJobs, 'completedEvent');
    $('#calendar').fullCalendar('removeEvents');
    $('#calendar').fullCalendar('addEventSource', this.eventList);
    $('#calendar').fullCalendar('rerenderEvents');
    $('#calendar').fullCalendar('gotoDate', moment(this.dateTimeHelper.getBaseDate(this.selectedDay)));
  }


}
