import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {AppComponent} from '../../../app.component';
import {AuthService} from '../../../core/services/auth.service';
import {ERROR_MESSAGES} from '../../../helpers/errors';
import {FranchisesService} from '../../../core/services/franchises.service';
import {nameValidator, emailValidator, passwordValidator, phoneValidator} from '../../../helpers/regex';
import {RegisterService} from '../../../core/services/register.service';
import {checkMatchingPasswords, noWhitespaceValidator, calculateAge} from '../../../core/shared/utils';
import {Operator} from '../../../core/interfaces/models/Operator';
import {GetOperatorsResponse} from '../../../core/interfaces/responses/GetOperatorsResponse';
import {UserService} from '../../../core/services/user.service';
import {SetUserStatusRequest} from '../../../core/interfaces/requests/SetUserStatusRequest';
import {AddOperatorRequest} from '../../../core/interfaces/requests/AddOperatorRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {ChangePasswordRequest} from '../../../core/interfaces/requests/ChangePasswordRequest';
import {HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'app-franchises',
  templateUrl: './franchises.component.html',
  styleUrls: ['./franchises.component.scss']
})

export class FranchisesComponent implements OnInit {


  protected operators: Array<Operator> = new Array<Operator>();
  protected selectedOperator: Operator;
  protected isRegistering: boolean;
  private isLoading: boolean;
  protected editingOperatorId: number;
  protected datepickerMaxDate: Date;
  protected operatorForm: FormGroup;
  protected changePasswordForm: FormGroup;
  private editingExistingOperator: boolean;
  protected changingPassword: boolean;
  protected passwordChangeSuccess: boolean;
  protected viewPassword: boolean;
  protected viewPasswordConfirm: boolean;
  protected viewPasswordEdit: boolean;
  protected viewPasswordConfirmEdit: boolean;
  protected errors;
  // protected calculateAge;
  protected age: number;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private franchisesService: FranchisesService, private userService: UserService, private formBuilder: FormBuilder,
              private registerService: RegisterService, private router: Router, private auth: AuthService, private app: AppComponent,
              private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.isRegistering = false;
    this.isLoading = false;
    this.datepickerMaxDate = new Date();
    this.viewPassword = false;
    this.viewPasswordConfirm = false;
    this.viewPasswordEdit = false;
    this.viewPasswordConfirmEdit = false;
    // this.calculateAge = calculateAge;
    this.errors = {
      GENERIC_ERROR_MESSAGE: null,
      MANDATORY_FIELDS_ERROR_MESSAGE: null,
      DUPLICATE_EMAIL_ERROR_MESSAGE: null,
      DUPLICATE_FRANCHISE_ERROR_MESSAGE: null,
      MAIL_SENDING_ERROR_MESSAGE: null
    };

    this.getOperators();
    this.initOperatorForm();
  }

  initOperatorForm(operator: Operator = null) {

    if (operator) {
      this.initFilledForm(operator);
    } else {
      this.initEmptyForm();
    }
  }

  private initEmptyForm() {
    this.operatorForm = this.formBuilder.group({
      franchiseCity: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      operatorFirstName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      operatorLastName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      operatorBirthday: ['', Validators.required],
      operatorDegree: ['', []],
      operatorDetails: [''],
      operatorPhone: ['', [Validators.required, phoneValidator, noWhitespaceValidator]],
      operatorAddress: ['', [Validators.required, noWhitespaceValidator]],
      operatorCity: ['', [Validators.required, noWhitespaceValidator]],
      operatorState: ['', [Validators.required, noWhitespaceValidator]],
      operatorCountry: ['', [Validators.required, noWhitespaceValidator]],
      operatorZipCode: ['', [Validators.required, noWhitespaceValidator]],
      operatorEmail: ['', [Validators.required, emailValidator, noWhitespaceValidator]],
      operatorPassword: ['', [Validators.required, passwordValidator]],
      operatorPasswordConfirm: ['', [Validators.required, passwordValidator, noWhitespaceValidator]],
      operatorTextarea: [''],
    }, {
      validator: checkMatchingPasswords('operatorPassword', 'operatorPasswordConfirm')
    });
  }

  private initFilledForm(operator: Operator) {
    this.operatorForm = this.formBuilder.group({
      franchiseCity: [operator.franchiseCity ? operator.franchiseCity : '',
        [Validators.required, nameValidator, noWhitespaceValidator]],
      operatorFirstName: [operator.user.firstName ? operator.user.firstName : '',
        [Validators.required, nameValidator, noWhitespaceValidator]],
      operatorLastName: [operator.user.lastName ? operator.user.lastName : '',
        [Validators.required, nameValidator, noWhitespaceValidator]],
      operatorBirthday: [operator.franchiseDate ? new Date(operator.franchiseDate) : '', [Validators.required]],
      operatorDegree: [operator.degree ? operator.degree : '', []],
      operatorDetails: [operator.about ? operator.about : ''],
      operatorPhone: [operator.user.phoneNumber ? operator.user.phoneNumber : '',
        [Validators.required, phoneValidator, noWhitespaceValidator]],
      operatorAddress: [operator.address ? operator.address : '', [Validators.required, noWhitespaceValidator]],
      operatorCity: [operator.city ? operator.city : '', [Validators.required, noWhitespaceValidator]],
      operatorState: [operator.state ? operator.state : '', [Validators.required, noWhitespaceValidator]],
      operatorCountry: [operator.country ? operator.country : '', [Validators.required, noWhitespaceValidator]],
      operatorZipCode: [operator.zipCode ? operator.zipCode : '', [Validators.required, noWhitespaceValidator]],
      operatorEmail: [operator.user.email ? operator.user.email : '', [Validators.required, emailValidator, noWhitespaceValidator]],
      operatorTextarea: [''],
    });
    this.changePasswordForm = this.formBuilder.group({
      operatorNewPassword: ['', [Validators.required, passwordValidator]],
      operatorNewPasswordConfirm: ['', [Validators.required, passwordValidator]],
    }, {
      validator: checkMatchingPasswords('operatorNewPassword', 'operatorNewPasswordConfirm')
    });
  }

  private initPasswordChange() {
    this.changingPassword = true;
  }

  /**
   * Marker: Retrieve Operators
   * =============================================================================================
   */

  private getOperators() {
    this.franchisesService.getOperators()
      .subscribe((response: GetOperatorsResponse) => {
          this.onGetOperatorsSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetOperatorsFailed(error);
        });

  }

  private onGetOperatorsSuccess(response: GetOperatorsResponse) {

    this.operators = response.operators;
    this.selectedOperator = this.operators[0] || null;
    this.age = calculateAge(this.selectedOperator.franchiseDate);
    console.log(this.selectedOperator.franchiseDate);
    this.isLoading = false;

  }

  private onGetOperatorsFailed(error: HttpErrorResponse) {
    console.log(error);
    this.isLoading = false;
  }

  /**
   * Marker: Add/Edit Operators
   * =============================================================================================
   */

  private getNewOperator(): AddOperatorRequest {

    let date = this.operatorForm.controls['operatorBirthday'].value;
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

    const params: AddOperatorRequest = {
      operator: {
        user: {
          firstName: this.operatorForm.controls['operatorFirstName'].value.trim(),
          lastName: this.operatorForm.controls['operatorLastName'].value.trim(),
          email: this.operatorForm.controls['operatorEmail'].value.trim(),
          phoneNumber: this.operatorForm.controls['operatorPhone'].value.trim(),
          password: null
        },
        operatorId: this.editingOperatorId ? this.editingOperatorId : null,
        franchiseCity: this.operatorForm.controls['franchiseCity'].value.trim(),
        degree: this.operatorForm.controls['operatorDegree'].value.trim(),
        about: this.operatorForm.controls['operatorDetails'].value.trim(),
        franchiseDate: date,
        address: this.operatorForm.controls['operatorAddress'].value.trim(),
        city: this.operatorForm.controls['operatorCity'].value.trim(),
        state: this.operatorForm.controls['operatorState'].value.trim(),
        country: this.operatorForm.controls['operatorCountry'].value.trim(),
        zipCode: this.operatorForm.controls['operatorZipCode'].value.trim(),
      },
      emailText: this.operatorForm.controls['operatorTextarea'].value.trim()
    };

    if (!this.editingExistingOperator) {
      params.operator.user.password = this.operatorForm.controls['operatorPassword'].value.trim();
    }

    return params;
  }

  private saveOperator() {
    this.isRegistering = true;

    if (this.editingExistingOperator) {
      this.editExistingOperator();
    } else {
      this.registerNewOperator();
    }
  }

  private registerNewOperator() {
    this.registerService.register(this.getNewOperator())
      .subscribe((response: BaseResponse) => {
        this.onSaveOperatorSuccess(response);
      }, (error) => {
        this.onSaveOperatorFailed(error);
      });
  }

  private editExistingOperator() {
    this.franchisesService.editOperator(this.getNewOperator())
      .subscribe((response: BaseResponse) => {
        this.onSaveOperatorSuccess(response);
      }, (error) => {
        this.onSaveOperatorFailed(error);
      });
  }

  private onSaveOperatorSuccess(response: BaseResponse) {
    if (response.success) {
      this.ngOnInit();
    } else {
      switch (response.message) {
        case ERROR_MESSAGES.DUPLICATE_EMAIL_ERROR_MESSAGE:
          this.errors.DUPLICATE_EMAIL_ERROR_MESSAGE = ERROR_MESSAGES.DUPLICATE_EMAIL_ERROR_MESSAGE;
          break;
        case ERROR_MESSAGES.DUPLICATE_FRANCHISE_ERROR_MESSAGE:
          this.elementRef.nativeElement.querySelector('#franchise-city').scrollIntoView();
          this.errors.DUPLICATE_FRANCHISE_ERROR_MESSAGE = ERROR_MESSAGES.DUPLICATE_FRANCHISE_ERROR_MESSAGE;
          break;
        case ERROR_MESSAGES.MAIL_SENDING_ERROR_MESSAGE:
          this.errors.MAIL_SENDING_ERROR_MESSAGE = ERROR_MESSAGES.MAIL_SENDING_ERROR_MESSAGE;
          break;
        default:
          this.errors.GENERIC_ERROR_MESSAGE = ERROR_MESSAGES.GENERIC_ERROR_MESSAGE;
      }
    }

    this.isRegistering = false;
  }

  private onSaveOperatorFailed(error: HttpErrorResponse) {
    console.log(error);
    this.isRegistering = false;
  }

  /**
   * Marker: Main Page Actions
   * =============================================================================================
   */

  editOperator(selectedOperator) {
    this.selectedOperator = null;
    this.editingExistingOperator = true;
    this.editingOperatorId = selectedOperator.operatorId || null;
    this.initOperatorForm(selectedOperator);
  }

  cancelEditing() {
    this.editingOperatorId = null;
    this.editingExistingOperator = false;
    this.changingPassword = false;
    this.initOperatorForm();
  }

  onSelectOperator(operator) {
    this.selectedOperator = operator;
    this.age = calculateAge(operator.franchiseDate);
  }



  /**
   * Marker: User status
   * =============================================================================================
   */

  setUserStatus() {
    this.selectedOperator.user.activeUser = !this.selectedOperator.user.activeUser;

    const params: SetUserStatusRequest = {
      userId: this.selectedOperator.user.userId,
      activeUser: this.selectedOperator.user.activeUser
    };

    this.userService.setUserStatus(params)
      .subscribe(response => console.log(response));
  }

  /**
   * Marker: Change Password
   * =============================================================================================
   */

  private changePassword() {
    const newPassword = this.changePasswordForm.controls['operatorNewPassword'].value;

    const params: ChangePasswordRequest = {
      userId: this.editingOperatorId,
      newPassword: newPassword,
      changedBy: (this.auth.getUserRole() === 'ROLE_OPERATOR') ? 1 : 0
    };

    this.franchisesService.changePassword(params)
      .subscribe((response: BaseResponse) => {
          this.onChangePasswordSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onChangePasswordFailed(error);
        });
  }

  private onChangePasswordSuccess(response: BaseResponse) {

    this.passwordChangeSuccess = true;
    setTimeout(() => {
      this.passwordChangeSuccess = false;
    }, 5000);

    this.changePasswordForm = this.formBuilder.group({
      operatorNewPassword: ['', [Validators.required, passwordValidator]],
      operatorNewPasswordConfirm: ['', [Validators.required, passwordValidator]],
    }, {
      validator: checkMatchingPasswords('operatorNewPassword', 'operatorNewPasswordConfirm')
    });
  }

  private onChangePasswordFailed(error: HttpErrorResponse) {
    console.log(error);
  }
}
