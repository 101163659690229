import {Component, Input, OnInit} from '@angular/core';
import {Note} from '../../../core/interfaces/models/Note';
import {FormBuilder} from '@angular/forms';
import {FamiliesService} from '../../../core/services/families.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {GetByIdRequest} from '../../../core/interfaces/requests/GetByIdRequest';
import {GetNotesResponse} from '../../../core/interfaces/responses/GetNotesResponse';
import {dayNames, monthNames} from '../../../core/shared/utils';
import {SittersService} from '../../../core/services/sitters.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';

@Component({
  selector: 'app-operator-notes',
  templateUrl: './operator-notes.component.html',
  styleUrls: ['./operator-notes.component.scss']
})
export class OperatorNotesComponent implements OnInit {

  @Input() familyId: number;
  @Input() sitterId: number;

  @Input() editingAllowed: boolean;


  protected notes: Array<Note>;
  protected selectedNote: Note;
  protected isAddingNote: boolean;
  protected emptyNote: boolean;
  protected noteDescriptionArea: string;
  protected showModal: boolean;
  protected privateNote: boolean;

  constructor(private formBuilder: FormBuilder, private familiesService: FamiliesService,
              private sittersService: SittersService, private modalService: NgbModal) {
    this.isAddingNote = false;
    this.emptyNote = false;
  }

  ngOnInit() {


    this.getNotes();
    this.showModal = false;
    this.closeNoteForm();
    this.initNoteForm();
  }

  initNoteForm() {
    this.showModal = true;
    if (this.selectedNote) {
      this.noteDescriptionArea = this.selectedNote.noteDescription;
      this.privateNote = this.selectedNote.privateNote;
    } else {
      this.noteDescriptionArea = null;
      this.privateNote = true;
    }
    const modal = document.getElementById('noteModal');
    if (modal) {
      modal.style.display = 'block';
    }

    console.log(this.privateNote);
  }

  closeNoteForm() {

    this.isAddingNote = false;
    this.emptyNote = false;
    this.noteDescriptionArea = null;
    this.privateNote = true;

    const modal = document.getElementById('noteModal');
    if (modal) {
      modal.style.display = 'none';
    }
  }


  /**
   * Marker: Get Notes
   * =============================================================================================
   */

  private getNotes() {
    if (this.familyId) {
      this.getFamilyNotes(this.familyId);
    }
    if (this.sitterId) {
      this.getSitterNotes(this.sitterId);
    }
  }

  private getFamilyNotes(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.familiesService.getFamilyNotes(params)
      .subscribe((response: GetNotesResponse) => {
        this.onGetFamilyNotesSuccess(response);
        // console.warn(this.sitterNotes);
      }, (error: HttpErrorResponse) => {
        this.onGetFamilyNotesFailed(error);
      });
  }

  private onGetFamilyNotesSuccess(response: GetNotesResponse) {
    this.notes = response.notes;

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetFamilyNotesFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private getSitterNotes(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.sittersService.getSitterNotes(params)
      .subscribe((response: GetNotesResponse) => {
        this.onGetSitterNotesSuccess(response);
        // console.warn(this.sitterNotes);
      }, (error: HttpErrorResponse) => {
        this.onGetSitterNotesFailed(error);
      });
  }

  private onGetSitterNotesSuccess(response: GetNotesResponse) {
    this.notes = response.notes;

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetSitterNotesFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Add Notes
   * =============================================================================================
   */

  private addNote() {

    if (this.noteDescriptionArea == null || this.noteDescriptionArea.length === 0 || this.noteDescriptionArea.replace(/^\s+/, '').replace(/\s+$/, '') === '') {
      this.emptyNote = true;
      return;
    }

    this.isAddingNote = true;
    const params = this.getNoteToSave();

    if (this.familyId) {
      this.addFamilyNote(params);
    }

    if (this.sitterId) {
      this.addSitterNote(params);
    }
  }

  private getNoteToSave(): Note {

    const date = new Date().getTime();
    let params: Note;

    if (this.familyId) {
      params = {
        familyId: this.familyId,
        noteDescription: this.noteDescriptionArea,
        privateNote: this.privateNote,
        noteDate: date
      };
    }

    if (this.sitterId) {
      params = {
        sitterId: this.sitterId,
        noteDescription: this.noteDescriptionArea,
        privateNote: this.privateNote,
        noteDate: date
      };
    }

    if (this.selectedNote) {
      params.noteId = this.selectedNote.noteId;
    } else {
      params.noteId = null;
    }

    return params;

  }

  private addFamilyNote(params: Note) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.familiesService.addFamilyNote(params)
      .subscribe((response: BaseResponse) => {
          this.onAddNoteSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onAddNoteFailed(error);
        });
  }

  private addSitterNote(params: Note) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.sittersService.addSitterNote(params)
      .subscribe((response: BaseResponse) => {
          this.onAddNoteSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onAddNoteFailed(error);
        });

  }

  private onAddNoteSuccess(response: BaseResponse) {
    this.isAddingNote = false;
    this.closeNoteForm();
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    this.getNotes();

  }

  private onAddNoteFailed(error: HttpErrorResponse) {
    this.isAddingNote = false;
    console.log(error);
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */


  public toDate(timestamp) {
    const d = new Date(timestamp);
    return monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
  }

  public getMonthName(timestamp) {
    const d = new Date(timestamp);
    return monthNames[d.getMonth()];
  }

  public getDayName(timestamp) {
    const d = new Date(timestamp);
    return d.getDate() + ' ' + dayNames[d.getDay()];
  }

}
