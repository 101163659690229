///<reference path="../../../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges} from '@angular/core';
import {SitterDetailsComponent} from '../../pages/sitter-details/sitter-details.component';
import {monthNames} from '../../../core/shared/utils';
import * as $ from 'jquery';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var jQuery: any;

@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.scss']
})
export class DashboardCalendarComponent implements OnInit, OnChanges {

  @Input() allJobs;
  @Output() changeMonth = new EventEmitter();
  @Output() openDetailsPageLocal = new EventEmitter();
  @Output() changeCalendarLocal = new EventEmitter();
  protected calendar;
  protected currentMonth: number;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
    this.onCarouselSlide();
    const date = new Date();
    this.currentMonth = date.getMonth();
    jQuery('#carouselMonths').carousel(this.currentMonth);
    if (this.currentMonth === 0) {
      console.log('init calendar, month 0', this.currentMonth);
      console.log(this.currentMonth);
      jQuery('#carouselMonths').next();
    }
    this.calendar = [];

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allJobs && changes.allJobs.currentValue && changes.allJobs.currentValue !== changes.allJobs.previousValue) {
      console.log('cahnges detected opn obs',changes.allJobs);

      if (this.constructCalendar(this.currentMonth) === 'done' && $('.carousel-control-next').hasClass('not-active')) {
        $('.carousel-control-next').toggleClass('not-active');
        $('.carousel-control-prev').toggleClass('not-active');


      }
    }
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  hideClass(className: string) {
    $('.' + className).toggleClass('hidden-job');
  }

  // TODO: improve this
  static getPreliminaryWeek() {
    const obj = {
      day: -1,
      type: '',
    };
    return [obj, obj, obj, obj, obj, obj, obj];
  }

  constructCalendar(monthNumber: number) {
    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.calendar = [];
    const currentYear = new Date().getFullYear();
    let numberOfWeeks = 0;

    this.calendar[0] = {};
    this.calendar[0].weeks = [];
    this.calendar[0].monthName = monthNames[monthNumber];
    this.calendar[0].monthNumber = monthNumber;
    this.calendar[0].weeks[numberOfWeeks] = SitterDetailsComponent.getPreliminaryWeek();

    for (let j = 1; j <= 31; j++) {

      const day = new Date(currentYear, monthNumber, j);

      // Javascript returns the next month if you create a date object with a day that isn't in that month
      // (new Date(2018, 2, 30) returns March second instead of Feb 30 or throwing an error)
      if (day.getMonth() !== monthNumber) {
        break;
      }
      const dayInfo = this.getDayInfo(j, monthNumber);
      this.calendar[0].weeks[numberOfWeeks][day.getDay()] = {
        day: day.getDate(),
        dayInfo: dayInfo
      };
      // If the day is the last in a week, add a new week to the month
      if (day.getDay() === 6) {
        numberOfWeeks++;
        this.calendar[0].weeks[numberOfWeeks] = SitterDetailsComponent.getPreliminaryWeek();
      }
    }
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    return 'done';
  }

  getDayInfo(dayNumber: number, monthNumber: number) {
    const infoArray = {
      'upcoming': [],
      'unassigned': [],
      'pending': [],
      'declined': [],
      'cancelled': [],
      'completed': []
    };


    if (this.allJobs) {

      this.constructArray(infoArray.upcoming, this.allJobs.upcomingJobs, dayNumber, monthNumber);
      this.constructArray(infoArray.unassigned, this.allJobs.unassignedJobs, dayNumber, monthNumber);
      this.constructArray(infoArray.pending, this.allJobs.pendingJobs, dayNumber, monthNumber);
      this.constructArray(infoArray.declined, this.allJobs.declinedJobs, dayNumber, monthNumber);
      this.constructArray(infoArray.cancelled, this.allJobs.canceledJobs, dayNumber, monthNumber);
      this.constructArray(infoArray.completed, this.allJobs.completedJobs, dayNumber, monthNumber);
    }

    return infoArray;


  }

  constructArray(targetArray, sourceArray, dayNumber: number, monthNumber: number) {
    for (let i = 0; i < sourceArray.length; i++) {
      let date = new Date(sourceArray[i].date);
      date = new Date(date.valueOf() + date.getTimezoneOffset() * 60000);
      if (date.getMonth() === monthNumber && date.getDate() === dayNumber) {
        let id;

        if (sourceArray[i].jobStatus === 'unassigned') {
          id = sourceArray[i].jobId;

        } else {
          id = sourceArray[i].jobId;
        }

        targetArray.push({
          'jobId': id,
          'jobStatus': sourceArray[i].jobStatus,
          'jobDate': sourceArray[i].date,
          'familyName': sourceArray[i].familyName,
          'startTime': sourceArray[i].startTime,
          'endTime': sourceArray[i].endTime,
          'address': sourceArray[i].address,
          'city': sourceArray[i].city,
          'state': sourceArray[i].state,
          'country': sourceArray[i].country,
          'zipCode': sourceArray[i].zipCode,
          'sitterLastName': sourceArray[i].sitterLastName
        });
      }
    }
  }

  onCarouselSlide() {
    jQuery('#carouselMonths').on('slid.bs.carousel', (event) => {
      this.currentMonth = event.to;
      console.log('on slide out', this.currentMonth);
      this.changeMonth.emit([monthNames[this.currentMonth]]);
      $('.carousel-control-prev').toggleClass('not-active');
      $('.carousel-control-next').toggleClass('not-active');
    });
  }

  openDetailsPage(jobId: number, jobStatus: string, jobDate: Date) {
    this.openDetailsPageLocal.emit([jobId, jobStatus, jobDate]);
  }

  changeCalendar() {
    const selectedOption = $('#filterType').val();
    this.changeCalendarLocal.emit([selectedOption]);
  }
}
