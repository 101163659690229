import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';


import { AppComponent } from './app.component';
import { MenuComponent } from './modules/components/menu/menu.component';
import { AppRoutingModule } from './app-routing.module';
import { FranchisesComponent } from './modules/pages/franchises/franchises.component';

import { EndpointService } from './core/services/endpoint.service';
import { ReportsComponent } from './modules/pages/reports/reports.component';
import { DashboardComponent } from './modules/pages/dashboard/dashboard.component';
import { FamiliesComponent } from './modules/pages/families/families.component';
import { SittersComponent } from './modules/pages/sitters/sitters.component';
import { OperationsComponent } from './modules/pages/operations/operations.component';
import { LoginComponent } from './modules/pages/login/login.component';
import { FamilyBillsComponent } from './modules/pages/family-bills/family-bills.component';
import { TokenInterceptor } from './helpers/interceptor';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateParserFormatter } from './helpers/CustomNgbDateParserFormatter';

import 'hammerjs';

import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatTableModule,
  MatSelectModule,
  MatIconModule,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoggedGuard } from './core/guards/logged.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { OperatorGuard } from './core/guards/operator.guard';
import { SitterDetailsComponent } from './modules/pages/sitter-details/sitter-details.component';
import { FamilyDetailsComponent } from './modules/pages/family-details/family-details.component';
import { AddSittersComponent } from './modules/components/add-sitters/add-sitters.component';
import { AddFamiliesComponent } from './modules/components/add-families/add-families.component';
import { DashboardCalendarComponent } from './modules/components/dashboard-calendar/dashboard-calendar.component';
import { DashboardSubmenuComponent } from './modules/components/dashboard-submenu/dashboard-submenu.component';
import { DayItemComponent } from './modules/components/dashboard-calendar/day-item/day-item.component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { DashboardDetailsPageComponent } from './modules/components/dashboard-details-page/dashboard-details-page.component';
import { DashboardDayCalendarComponent } from './modules/components/dashboard-day-calendar/dashboard-day-calendar.component';
import { JobMainDetailsComponent } from './modules/components/job-main-details/job-main-details.component';
import { FamilyInfoComponent } from './modules/components/family-info/family-info.component';
import { OperatorNotesComponent } from './modules/components/operator-notes/operator-notes.component';
import { JobsListComponent } from './modules/components/jobs-list/jobs-list.component';
import { FavoritesComponent } from './modules/components/favorites/favorites.component';
import { LoadingSpinnerComponent } from './modules/components/loading-spinner/loading-spinner.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {FamilyPaymentsComponent} from './modules/pages/family-payments/family-payments.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FranchisesComponent,
    ReportsComponent,
    DashboardComponent,
    FamiliesComponent,
    SittersComponent,
    OperationsComponent,
    LoginComponent,
    SitterDetailsComponent,
    FamilyDetailsComponent,
    AddSittersComponent,
    AddFamiliesComponent,
    DashboardComponent,
    DashboardCalendarComponent,
    DashboardSubmenuComponent,
    DayItemComponent,
    DashboardDetailsPageComponent,
    DashboardDayCalendarComponent,
    JobMainDetailsComponent,
    FamilyInfoComponent,
    OperatorNotesComponent,
    JobsListComponent,
    FavoritesComponent,
    LoadingSpinnerComponent,
    FamilyBillsComponent,
    FamilyPaymentsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTableModule,
    MatSelectModule,
    MatIconModule,
    NgbModule,
    FullCalendarModule
  ],
  providers: [
    AppComponent,
    EndpointService,
    AdminGuard,
    OperatorGuard,
    LoggedGuard,
    JwtHelperService, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter('longDate')}
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoadingSpinnerComponent]
})
export class AppModule { }
