import {dayNames, monthNames} from '../core/shared/utils';
import {Injectable} from '@angular/core';
import * as $ from 'jquery';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DateTimeHelper {

  constructor() {

  }

  public timeConvertor(time) {
    var elem = time.split(' ');
    var stSplit = elem[0].split(':');
    var stHour = Number.parseInt(stSplit[0]);
    var stMin = Number.parseInt(stSplit[1]);
    var stAmPm = elem[1];

    if (stAmPm === 'PM') {
      if (stHour !== 12) {
        stHour = stHour * 1 + 12;
      }
    } else if (stAmPm === 'AM' && stHour === 12) {
      stHour = stHour - 12;
    } else {
      stHour = stHour;
    }
    return [stHour, stMin];
  }

  public getDayName(timestamp: number) {
    let day = new Date(timestamp);
    day = new Date(day.valueOf() + day.getTimezoneOffset() * 60000);
    let dayString = dayNames[day.getDay()] + ', ' + monthNames[day.getMonth()] + ' ' + day.getDate();
    if (day.getDate() % 10 === 1) {
      dayString += 'ST';
    } else if (day.getDate() % 10 === 2) {
      dayString += 'ND';
    } else if (day.getDate() % 10 === 3) {
      dayString += 'RD';
    } else {
      dayString += 'TH';
    }
    return dayString;
  }

  public getBaseDate(d: Date) {
    return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
  }

  public getMonthName(timestamp: number) {
    const d = new Date(timestamp);
    const date = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
    return monthNames[date.getMonth()];
  }

  public getDayNameSimple(timestamp: number) {
    const d = new Date(timestamp);
    const date = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
    return dayNames[date.getDay()];
  }

  checkIfToday(checkedDate: Date) {
    const currentDate = new Date();
    checkedDate = new Date(checkedDate);
    checkedDate = new Date(checkedDate.valueOf() + checkedDate.getTimezoneOffset() * 60000);
    if (checkedDate.getFullYear() === currentDate.getFullYear() && checkedDate.getMonth() === currentDate.getMonth() && checkedDate.getDate() === currentDate.getDate()) {
      return true;
    }
    return false;
  }

}
