import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class LoggedGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate() {
    const localToken = AuthService.getSessionToken();
    if (localToken) {
      return true;
    } else {
      return this.refuse();
    }
  }

  refuse(): boolean {
    this.router.navigate(['/']);
    return false;
  }
}
