import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointService } from './endpoint.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SittersService {

  constructor(private http: HttpClient) { }

  addSitter(data): Observable<any> {
    return this.http.post(EndpointService.addSitterUrl, data, AuthService.setHeader());
  }

  getSitters(data): Observable<any> {
    return this.http.post(EndpointService.getSitters, data, AuthService.setHeader());
  }

  getSitterDetails(data): Observable<any> {
    return this.http.post(EndpointService.getSitterById, data, AuthService.setHeader());
  }

  editSitterContactInfo(data): Observable<any> {
    return this.http.post(EndpointService.editSitterContactInfo, data, AuthService.setHeader());
  }

  editSitterAboutInfo(data): Observable<any> {
    return this.http.post(EndpointService.editSitterAboutInfo, data, AuthService.setHeader());
  }

  getSitterNotes(data): Observable<any> {
    return this.http.post(EndpointService.getSitterNotes, data, AuthService.setHeader());
  }

  addSitterNote(data): Observable<any> {
    return this.http.post(EndpointService.addSitterNote, data, AuthService.setHeader());
  }

  searchSitters(data): Observable<any> {
    return this.http.post(EndpointService.searchSitters, data, AuthService.setHeader());
  }

  getSitterFavoritFamilies(data): Observable<any> {
    return this.http.post(EndpointService.getSitterFavoritFamilies, data, AuthService.setHeader());
  }

  setSitterStatus(data): Observable<any> {
    return this.http.post(EndpointService.userStatus, data, AuthService.setHeader());
  }

  getSitterJobs(data): Observable<any> {
    return this.http.post(EndpointService.getSitterJobs, data, AuthService.setHeader());
  }

  getSitterAvailability(data): Observable<any> {
    return this.http.post(EndpointService.getSitterAvailability, data, AuthService.setHeader());
  }

  editSitterAvailability(data): Observable<any> {
    return this.http.post(EndpointService.editSitterAvailability, data, AuthService.setHeader());
  }

  addSitterAvailability(data): Observable<any> {
    return this.http.post(EndpointService.addSitterAvailability, data, AuthService.setHeader());
  }

  removeAvailability(data): Observable<any> {
    return this.http.post(EndpointService.removeAvailability, data, AuthService.setHeader());
  }

  getSittersForJobAssigning(data): Observable<any> {
    return this.http.post(EndpointService.getSittersForJobAssigning, data, AuthService.setHeader());
  }

  searchSittersForJobAssigning(data): Observable<any> {
    return this.http.post(EndpointService.searchSittersForJobAssigning, data, AuthService.setHeader());
  }

}
