import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';
import {SendNotificationToSitterRequest} from '../interfaces/requests/SendNotificationToSitterRequest';
import {GetAllJobsRequest} from '../interfaces/requests/GetAllJobsRequest';
import {JobAdditionalInfo} from '../interfaces/models/JobAdditionalInfo';
import {ChangeJobStatusRequest} from '../interfaces/requests/ChangeJobStatusRequest';
import {AssignSitterRequest} from '../interfaces/requests/AssignSitterRequest';
import {EditCommentForCompletedJobRequest} from '../interfaces/requests/EditCommentForCompletedJobRequest';
import {BaseResponse} from '../interfaces/responses/BaseResponse';
import {EditJobRequest} from '../interfaces/requests/EditJobRequest';
import {GetByIdRequest} from '../interfaces/requests/GetByIdRequest';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) {
  }

  getAllJobs(data: GetAllJobsRequest): Observable<any> {
    return this.http.post(EndpointService.getAllJobs, data, AuthService.setHeader());
  }

  getAdditionalDetailsForJob(data: GetByIdRequest): Observable<any> {
    return this.http.post(EndpointService.getAdditionalDetailsForJob, data, AuthService.setHeader());
  }

  sendNotificationToSitter(data: SendNotificationToSitterRequest): Observable<any> {
    return this.http.post(EndpointService.sendNotificationToSitter, data, AuthService.setHeader());
  }

  changeJobStatus(data: ChangeJobStatusRequest): Observable<any> {
    return this.http.post(EndpointService.changeJobStatus, data, AuthService.setHeader());
  }

  assignSitter(data: AssignSitterRequest): Observable<any> {
    return this.http.post(EndpointService.assignSitter, data, AuthService.setHeader());
  }

  editCommentForCompletedJob(data: EditCommentForCompletedJobRequest): Observable<any> {
    return this.http.post(EndpointService.editCompletedJobComment, data, AuthService.setHeader());
  }

  editJobDetails(data: EditJobRequest): Observable<any> {
    return this.http.post(EndpointService.editJobDetails, data, AuthService.setHeader());
  }


}
