import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointService } from './endpoint.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  register(registerObject): Observable<any> {
    return this.http.post(EndpointService.addOperatorUrl, registerObject, AuthService.setHeader());
  }
}
