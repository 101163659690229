import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SittersService} from '../../../core/services/sitters.service';
import {
  calculateAge,
  monthNames,
  dayNames,
  noWhitespaceValidator,
  checkValidAvailableTime,
  checkValidAvailableDate
} from '../../../core/shared/utils';
import {CompleteSitterInfo} from '../../../core/interfaces/models/CompleteSitterInfo';
import {GetSitterDetailsResponse} from '../../../core/interfaces/responses/GetSitterDetailsResponse';
import {GetByIdRequest} from '../../../core/interfaces/requests/GetByIdRequest';
import {UserService} from '../../../core/services/user.service';
import {SetUserStatusRequest} from '../../../core/interfaces/requests/SetUserStatusRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {GetSitterJobsResponse} from '../../../core/interfaces/responses/GetSitterJobsResponse';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import {GetSitterAvailabilityResponse} from '../../../core/interfaces/responses/GetSitterAvailabilityResponse';
import {HttpErrorResponse} from '@angular/common/http';
import {EditSitterContactRequest} from '../../../core/interfaces/requests/EditSitterContactRequest';
import {EditSitterAboutRequest} from '../../../core/interfaces/requests/EditSitterAboutRequest';
import {EditSitterAvailabilityRequest} from '../../../core/interfaces/requests/EditSitterAvailabilityRequest';
import {SetSitterAvailabilityRequest} from '../../../core/interfaces/requests/SetSitterAvailabilityRequest';
import {FormBuilder, FormGroup, RequiredValidator, Validators} from '@angular/forms';
import {emailValidator, monthValidator, nameValidator, phoneValidator} from '../../../helpers/regex';
import {NgbCalendar, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import {DateTimeHelper} from '../../../helpers/date-time-helper';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {AddJobRequest} from '../../../core/interfaces/requests/AddJobRequest';


@Component({
  selector: 'app-sitter-details',
  templateUrl: './sitter-details.component.html',
  styleUrls: ['./sitter-details.component.scss'],
})
export class SitterDetailsComponent implements OnInit {

  protected sitterId;
  private sitterAge = calculateAge;

  protected sitterDetails: CompleteSitterInfo;
  protected sitterUpcomingJobs: Array<JobInfo>;
  protected sitterCompletedJobs: Array<JobInfo>;
  protected sitterAvailability: GetSitterAvailabilityResponse;
  protected sitterAvailabilities: Array<any>;
  protected sitterAgeInfo: NgbDateStruct;
  protected sitterOcupation: string;
  protected sitterCertifications: Array<string>;
  protected sitterHobbies: Array<string>;
  protected sitterAbout: string;
  protected calendar = [];
  protected itemPluralMapping = {
    'jobUpcoming': {
      '=0': ' UPCOMING JOBS | ',
      '=1': ' UPCOMING JOB | ',
      'other': ' UPCOMING JOBS | '
    },
    'jobCompleted': {
      '=0': ' COMPLETED JOBS | ',
      '=1': ' COMPLETED JOB | ',
      'other': ' COMPLETED JOBS | '
    },
    'day': {
      '=0': ' AVAILABLE DAYS',
      '=1': ' AVAILABLE DAY',
      'other': ' AVAILABLE DAYS'
    }
  };
  protected editContactFlag: boolean;
  protected editAboutFlag: boolean;
  protected avRepeatFlag: boolean;
  protected avIsWeekFlag: boolean;
  protected availabilityId: number;
  protected contactForm: FormGroup;
  protected availabilityForm: FormGroup;
  protected minDateValue: object;
  protected dateTimeHelper: DateTimeHelper;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private availabilityCalendar: NgbCalendar, private formBuilder: FormBuilder, private route: ActivatedRoute,
              private userService: UserService, private sittersService: SittersService,
              private modalService: NgbModal, private ngbDateParserFormatter: NgbDateParserFormatter) {
    this.editContactFlag = false;
    this.editAboutFlag = false;
    this.avRepeatFlag = false;
    this.avIsWeekFlag = false;
    this.sitterUpcomingJobs = new Array<JobInfo>();
    this.sitterCompletedJobs = new Array<JobInfo>();
    this.sitterAvailability = null;
    this.sitterDetails = null;
    this.sitterCertifications = new Array<string>();
    this.sitterHobbies = new Array<string>();
    this.sitterAvailabilities = new Array<object>();
    this.availabilityId = null;
    this.dateTimeHelper = new DateTimeHelper();
    const date = new Date();
    this.minDateValue = {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()};
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.sitterId = params['id'];
      this.getSitterDetails(this.sitterId);
      this.getSitterJobs(this.sitterId);
    });
    this.sitterOcupation = null;
    this.sitterAbout = null;
    this.initForms();
    this.sitterAgeInfo = this.setDefaultDate();
    this.onSelectDate(this.sitterAgeInfo);
  }

  initForms() {
    if (this.sitterDetails) {
      this.contactForm = this.formBuilder.group({
        phoneNumber: [this.sitterDetails.phoneNumber, [phoneValidator, noWhitespaceValidator, RequiredValidator]],
        email: [this.sitterDetails.email, [emailValidator, noWhitespaceValidator, RequiredValidator]],
        permAddress: [this.sitterDetails.permAddress, []],
        permCity: [this.sitterDetails.permCity, []],
        permState: [this.sitterDetails.permState, []],
        permCountry: [this.sitterDetails.permCountry, []],
        permZipCode: [this.sitterDetails.permZipCode, []],
        currentAddress: [this.sitterDetails.currentAddress, []],
        currentCity: [this.sitterDetails.currentCity, []],
        currentState: [this.sitterDetails.currentState, []],
        currentCountry: [this.sitterDetails.currentCountry, []],
        currentZipCode: [this.sitterDetails.currentZipCode, []],
        emergencyContactName: [this.sitterDetails.emergencyContactName, [nameValidator]],
        emergencyContactRelationship: [this.sitterDetails.emergencyContactRelationship, [nameValidator]],
        emergencyContactPhone: [this.sitterDetails.emergencyContactPhone, [phoneValidator]]
      });
    } else {
      this.contactForm = this.formBuilder.group({
        phoneNumber: ['', [phoneValidator, noWhitespaceValidator, RequiredValidator]],
        email: ['', [emailValidator, noWhitespaceValidator, RequiredValidator]],
        permAddress: ['', []],
        permCity: ['', []],
        permState: ['', []],
        permCountry: ['', []],
        permZipCode: ['', []],
        currentAddress: ['', []],
        currentCity: ['', []],
        currentState: ['', []],
        currentCountry: ['', []],
        currentZipCode: ['', []],
        emergencyContactName: ['', [nameValidator]],
        emergencyContactRelationship: ['', [nameValidator]],
        emergencyContactPhone: ['', [phoneValidator]]
      });
    }
    this.availabilityForm = this.formBuilder.group({
      avIsAvailable: ['', [Validators.required]],
      avStartDate: [this.availabilityCalendar.getToday(), [Validators.required]],
      avEndDate: [this.availabilityCalendar.getToday(), [Validators.required]],
      avStartTime: [{hour: 12, minute: 0}, [Validators.required]],
      avEndTime: [{hour: 13, minute: 0}, [Validators.required]],
      avIsRepetitive: ['', [Validators.required, noWhitespaceValidator]],
      avRepeatOption: ['', [Validators.required, noWhitespaceValidator]],
      avRepeatType: ['', [Validators.required, noWhitespaceValidator]],
      avRepeatDay: ['', [Validators.required, noWhitespaceValidator]]
    }, {
      validator: [checkValidAvailableTime('avStartTime', 'avEndTime'), checkValidAvailableDate('avStartDate', 'avEndDate', 'avIsRepetitive')]
    });
  }


  /**
   * Marker: Status
   * =============================================================================================
   */

  private setSitterStatus() {
    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.sitterDetails.activeUser = !this.sitterDetails.activeUser;

    const params: SetUserStatusRequest = {
      userId: this.sitterId,
      activeUser: this.sitterDetails.activeUser
    };

    this.userService.setUserStatus(params)
      .subscribe(response => {

        LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
      });
  }


  /**
   * Marker: Details
   * =============================================================================================
   */

  private getSitterDetails(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.sittersService.getSitterDetails(params)
      .subscribe((response: GetSitterDetailsResponse) => {
          this.onGetSitterDetailsSuccess(response);
          this.initForms();
        },
        (error: HttpErrorResponse) => {
          this.onGetSitterDetailsFailed(error);
        });
  }

  private onGetSitterDetailsSuccess(response: GetSitterDetailsResponse) {
    this.sitterDetails = response.sitter;
    let dob = new Date(this.sitterDetails.birthdate);
    dob = new Date(dob.valueOf() + dob.getTimezoneOffset() * 60000);
    this.sitterAgeInfo = {
      'year': dob.getFullYear(),
      'month': dob.getMonth(),
      'day': dob.getDate()
    };
    this.getSitterCertifications();
    this.getSitterHobbies();

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetSitterDetailsFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private editSitterContactInfo() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: EditSitterContactRequest = {
      byOperator: sessionStorage.getItem('operatorEmail'),
      sitterId: Number.parseInt(this.sitterId),
      contactInfo: {
        phoneNumber: (this.contactForm.controls['phoneNumber'].value == null || this.contactForm.controls['phoneNumber'].value.length === 0) ? this.sitterDetails.phoneNumber : this.contactForm.controls['phoneNumber'].value,
        email: (this.contactForm.controls['email'].value == null || this.contactForm.controls['email'].value.length === 0) ? this.sitterDetails.email : this.contactForm.controls['email'].value,
        permAddress: (this.contactForm.controls['permAddress'].value == null || this.contactForm.controls['permAddress'].value.length === 0) ? this.sitterDetails.permAddress : this.contactForm.controls['permAddress'].value,
        permCity: (this.contactForm.controls['permCity'].value == null || this.contactForm.controls['permCity'].value.length === 0) ? this.sitterDetails.permCity : this.contactForm.controls['permCity'].value,
        permState: (this.contactForm.controls['permState'].value == null || this.contactForm.controls['permState'].value.length === 0) ? this.sitterDetails.permState : this.contactForm.controls['permState'].value,
        permCountry: (this.contactForm.controls['permCountry'].value == null || this.contactForm.controls['permCountry'].value.length === 0) ? this.sitterDetails.permCountry : this.contactForm.controls['permCountry'].value,
        permZipCode: (this.contactForm.controls['permZipCode'].value == null || this.contactForm.controls['permZipCode'].value.length === 0) ? this.sitterDetails.permZipCode : this.contactForm.controls['permZipCode'].value,
        currentAddress: (this.contactForm.controls['currentAddress'].value == null || this.contactForm.controls['currentAddress'].value.length === 0) ? this.sitterDetails.currentAddress : this.contactForm.controls['currentAddress'].value,
        currentCity: (this.contactForm.controls['currentCity'].value == null || this.contactForm.controls['currentCity'].value.length === 0) ? this.sitterDetails.currentCity : this.contactForm.controls['currentCity'].value,
        currentState: (this.contactForm.controls['currentState'].value == null || this.contactForm.controls['currentState'].value.length === 0) ? this.sitterDetails.currentState : this.contactForm.controls['currentState'].value,
        currentCountry: (this.contactForm.controls['currentCountry'].value == null || this.contactForm.controls['currentCountry'].value.length === 0) ? this.sitterDetails.currentCountry : this.contactForm.controls['currentCountry'].value,
        currentZipCode: (this.contactForm.controls['currentZipCode'].value == null || this.contactForm.controls['currentZipCode'].value.length === 0) ? this.sitterDetails.currentZipCode : this.contactForm.controls['currentZipCode'].value,
        emergencyContactName: (this.contactForm.controls['emergencyContactName'].value == null || this.contactForm.controls['emergencyContactName'].value.length === 0) ? this.sitterDetails.emergencyContactName : this.contactForm.controls['emergencyContactName'].value,
        emergencyContactRelationship: (this.contactForm.controls['emergencyContactRelationship'].value == null || this.contactForm.controls['emergencyContactRelationship'].value.length === 0) ? this.sitterDetails.emergencyContactRelationship : this.contactForm.controls['emergencyContactRelationship'].value,
        emergencyContactPhone: (this.contactForm.controls['emergencyContactPhone'].value == null || this.contactForm.controls['emergencyContactPhone'].value.length === 0) ? this.sitterDetails.emergencyContactPhone : this.contactForm.controls['emergencyContactPhone'].value
      }
    };

    this.sittersService.editSitterContactInfo(params)
      .subscribe((response: BaseResponse) => {
          console.log(params);
          this.oneditSitterContactInfoSuccess(response);
          console.log(response);
        },
        (error: HttpErrorResponse) => {
          this.oneditSitterContactInfoFailed(error);
        });
  }

  private oneditSitterContactInfoSuccess(response: BaseResponse) {
    this.editContactFlag = false;
    this.getSitterDetails(this.sitterId);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private oneditSitterContactInfoFailed(error: HttpErrorResponse) {
    console.log(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private editSitterAboutInfo() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    let birthdate = null;
    if (this.sitterAgeInfo == null || this.sitterAge.length === 0) {
      birthdate = this.sitterDetails.birthdate;
      birthdate = new Date(birthdate);
      birthdate = new Date(Date.UTC(birthdate.getFullYear(), birthdate.getMonth(), birthdate.getDate()));
    } else {
      birthdate = new Date(Date.UTC(this.sitterAgeInfo.year, this.sitterAgeInfo.month, this.sitterAgeInfo.day));
    }

    const params: EditSitterAboutRequest = {
      sitterId: Number.parseInt(this.sitterId),
      firstName: this.sitterDetails.firstName,
      lastName: this.sitterDetails.lastName,
      birthdate: birthdate,
      occupation: (this.sitterOcupation == null || this.sitterOcupation.length === 0) ? this.sitterDetails.occupation : this.sitterOcupation,
      certifications: this.sitterCertifications,
      hobbies: this.sitterHobbies,
      about: (this.sitterAbout == null || this.sitterAbout.length === 0) ? this.sitterDetails.about : this.sitterAbout
    };
    this.sittersService.editSitterAboutInfo(params)
      .subscribe((response: BaseResponse) => {
          this.oneditSitterAboutInfoSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.oneditSitterAboutInfoFailed(error);
        });
  }

  private oneditSitterAboutInfoSuccess(response: BaseResponse) {
    this.editAboutFlag = false;
    this.getSitterDetails(this.sitterId);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private oneditSitterAboutInfoFailed(error: HttpErrorResponse) {
    console.log(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }


  /**
   * Marker: Jobs
   * =============================================================================================
   */


  private getSitterJobs(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.sittersService.getSitterJobs(params)
      .subscribe((response: GetSitterJobsResponse) => {
        this.onGetSitterJobsSuccess(response);
      }, (error) => {
        this.onGetSitterJobsFailed(error);
      });
  }

  private onGetSitterJobsSuccess(response: GetSitterJobsResponse) {
    if (response.success) {
      this.sitterCompletedJobs = response.completedJobs;
      this.sitterUpcomingJobs = response.upcomingJobs;
      this.getSitterAvailability(this.sitterId);
    } else {
      console.error('got error: ' + response.message);
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetSitterJobsFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }


  /**
   * Marker: Availabilities
   * =============================================================================================
   */

  private getSitterAvailability(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.sittersService.getSitterAvailability(params)
      .subscribe((response: GetSitterAvailabilityResponse) => {
        this.onGetSitterAvailabilitySuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onGetSitterJobsFailed(error);
      });
  }

  private onGetSitterAvailabilitySuccess(response: GetSitterAvailabilityResponse) {
    if (response.success) {
      this.sitterAvailability = response;
      this.sitterAvailabilities = [];
      for (let i = 0; i < this.sitterAvailability.availabilities.length; i++) {
        for (let j = 0; j < this.sitterAvailability.availabilities[i].actualDates.length; j++) {
          this.sitterAvailabilities.push({'actualDate': this.sitterAvailability.availabilities[i].actualDates[j], 'availabilityId': i});
        }
      }
    } else {
      console.error('got error: ' + response.message);
    }

    this.constructCalendar();

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetSitterAvailabilityFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private editSitterAvailability() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    let dateDefault = new Date();
    dateDefault = new Date(dateDefault);
    dateDefault = new Date(Date.UTC(dateDefault.getFullYear(), dateDefault.getMonth(), dateDefault.getDate()));

    const startDate = new Date(Date.UTC(this.availabilityForm.controls['avStartDate'].value.year,
      (this.availabilityForm.controls['avStartDate'].value.month - 1), this.availabilityForm.controls['avStartDate'].value.day));
    let endDate = new Date(Date.UTC(this.availabilityForm.controls['avEndDate'].value.year, (this.availabilityForm.controls['avEndDate'].value.month - 1), this.availabilityForm.controls['avEndDate'].value.day));
    if (this.availabilityForm.controls['avIsRepetitive'].value === 'false') {
      endDate = startDate;
    }
    const isRepetitiveJob = (this.availabilityForm.controls['avIsRepetitive'].value === 'true');

    if (this.availabilityId === null) {
      const params: SetSitterAvailabilityRequest = {
        sitterId: Number.parseInt(this.sitterId),
        availabilities: [{
          isAvailable: (this.availabilityForm.controls['avIsAvailable'].value === 'true'),
          unavailabilityReason: 'Test case',
          startDate: (this.availabilityForm.controls['avStartDate'].value === null) ? dateDefault : startDate,
          endDate: (this.availabilityForm.controls['avEndDate'].value === null) ? dateDefault : endDate,
          startTime: this.availabilityForm.controls['avStartTime'].value.hour + ':' + this.availabilityForm.controls['avStartTime'].value.minute,
          endTime: this.availabilityForm.controls['avEndTime'].value.hour + ':' + this.availabilityForm.controls['avEndTime'].value.minute,
          isRepetitive: isRepetitiveJob,
          repeatOption: isRepetitiveJob === true ? this.availabilityForm.controls['avRepeatOption'].value : null,
          repeatType: isRepetitiveJob === true ? this.availabilityForm.controls['avRepeatType'].value : null,
          repeatDay: isRepetitiveJob === true ? this.availabilityForm.controls['avRepeatDay'].value : null
        }]
      };
      this.sittersService.addSitterAvailability(params)
        .subscribe((response: BaseResponse) => {
          this.onEditSitterAvailabilitySuccess(response);
        }, (error: HttpErrorResponse) => {
          this.onEditSitterAvailabilityFailed(error);
        });
    } else {
      const params: EditSitterAvailabilityRequest = {
        availabilityId: this.availabilityId,
        isAvailable: (this.availabilityForm.controls['avIsAvailable'].value === 'true'),
        unavailabilityReason: 'Test case',
        startDate: (this.availabilityForm.controls['avStartDate'].value === null) ? dateDefault : startDate,
        endDate: (this.availabilityForm.controls['avEndDate'].value === null) ? dateDefault : endDate,
        startTime: this.availabilityForm.controls['avStartTime'].value.hour + ':' + this.availabilityForm.controls['avStartTime'].value.minute,
        endTime: this.availabilityForm.controls['avEndTime'].value.hour + ':' + this.availabilityForm.controls['avEndTime'].value.minute,
        isRepetitive: isRepetitiveJob,
        repeatOption: isRepetitiveJob === true ? this.availabilityForm.controls['avRepeatOption'].value : null,
        repeatType: isRepetitiveJob === true ? this.availabilityForm.controls['avRepeatType'].value : null,
        repeatDay: isRepetitiveJob === true ? this.availabilityForm.controls['avRepeatDay'].value : null
      };
      this.sittersService.editSitterAvailability(params)
        .subscribe((response: BaseResponse) => {
          this.onEditSitterAvailabilitySuccess(response);
        }, (error: HttpErrorResponse) => {
          this.onEditSitterAvailabilityFailed(error);
        });
    }

  }

  private onEditSitterAvailabilityFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onEditSitterAvailabilitySuccess(response: BaseResponse) {

    if (!response.success) {
      LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
      alert(response.message);
    } else {
      this.getSitterJobs(this.sitterId);
      LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    }

  }

  removeAvailability(siiterAvailabilitiesId: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const rawData = this.sitterAvailability.availabilities[this.sitterAvailabilities[siiterAvailabilitiesId].availabilityId].rawAvailability;

    const params = {
      id: rawData.availabilityId
    };
    this.sittersService.removeAvailability(params)
      .subscribe((response: BaseResponse) => {
          this.onRemoveAvailabilitySuccess();
        },
        (error: HttpErrorResponse) => {
          this.onRemoveAvailabilityFailed(error);
        });
  }

  private onRemoveAvailabilitySuccess() {
    this.getSitterJobs(this.sitterId);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onRemoveAvailabilityFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  toDate(timestamp) {
    let d = new Date(timestamp);
    d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
    return monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
  }

  getMonthName(timestamp) {
    let d = new Date(timestamp);
    d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
    return monthNames[d.getMonth()];
  }

  getDayName(timestamp) {
    let d = new Date(timestamp);
    d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
    return d.getDate() + ' ' + dayNames[d.getDay()];
  }

  constructCalendar() {
    const currentYear = new Date().getFullYear();

    for (let i = 0; i < 12; i++) {

      let numberOfWeeks = 0;

      this.calendar[i] = {};
      this.calendar[i].weeks = [];
      this.calendar[i].monthName = monthNames[i];
      this.calendar[i].monthNumber = i;
      this.calendar[i].weeks[numberOfWeeks] = SitterDetailsComponent.getPreliminaryWeek();

      for (let j = 1; j <= 31; j++) {

        const day = new Date(currentYear, i, j);

        // Javascript returns the next month if you create a date object with a day that isn't in that month
        // (new Date(2018, 2, 30) returns March second instead of Feb 30 or throwing an error)
        if (day.getMonth() !== i) {
          break;
        }
        const dayInfo = this.getDayInfo(j, i);
        this.calendar[i].weeks[numberOfWeeks][day.getDay()] = {
          day: day.getDate(),
          dayInfo: dayInfo,
          popoverDisabled: (dayInfo.length === 0) ? true : false
        };
        // If the day is the last in a week, add a new week to the month
        if (day.getDay() === 6) {
          numberOfWeeks++;
          this.calendar[i].weeks[numberOfWeeks] = SitterDetailsComponent.getPreliminaryWeek();
        }
      }
    }
  }

  // TODO: improve this
  static getPreliminaryWeek() {
    const obj = {
      day: -1,
      type: '',
    };
    return [obj, obj, obj, obj, obj, obj, obj];
  }

  getDayType(selectedDay, selectedMonth) {
    let className = '';
    this.sitterCompletedJobs.forEach(function (element) {
      let d = new Date(element.date);
      d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);

      if (d.getDate() === selectedDay && d.getMonth() === selectedMonth) {
        className = 'completed';
      }
    });
    this.sitterUpcomingJobs.forEach(function (element) {
      let d = new Date(element.date);
      d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
      if (d.getDate() === selectedDay && d.getMonth() === selectedMonth) {
        if (className === 'completed') {
          className = className + '-upcoming';
        } else if (className === '') {
          className = 'upcoming';
        }
      }
    });
    this.sitterAvailabilities.forEach(function (element) {
      let d = new Date(element.actualDate);
      d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
      if (d.getDate() === selectedDay && d.getMonth() === selectedMonth) {
        if (className === 'completed-upcoming' || className === 'upcoming') {
          className = className + '-available';
        } else if (className === '') {
          className = 'available';
        }
      }
    });

    return className;
  }

  addNewCertification() {
    const newItemNo = this.sitterCertifications.length + 1;
    this.sitterCertifications.push('');
  }

  removeCertification(index: number) {
    if (index > -1) {
      this.sitterCertifications.splice(index, 1);
    }
  }

  getSitterCertifications() {
    this.sitterCertifications = [];
    if (this.sitterDetails) {
      this.sitterDetails.sitterCertifications.forEach(function (item) {
        this.sitterCertifications.push(item);
      }, this);
    }
  }

  addNewHobby() {
    const newItemNo = this.sitterHobbies.length + 1;
    this.sitterHobbies.push('');
  }

  removeHobby(index: number) {
    if (index > -1) {
      this.sitterHobbies.splice(index, 1);
    }
  }

  getSitterHobbies() {
    this.sitterHobbies = [];
    if (this.sitterDetails) {
      this.sitterDetails.sitterHobbies.forEach(function (item) {
        this.sitterHobbies.push(item);
      }, this);
    }
  }

  indexTracker(index: number, value: any) {
    return index;
  }

  getDayInfo(selectedDay, selectedMonth) {
    const popupContent = [];

    for (let i = 0; i < this.sitterAvailabilities.length; i++) {
      let d = new Date(this.sitterAvailabilities[i].actualDate);
      d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);

      if (d.getDate() === selectedDay && d.getMonth() === selectedMonth) {
        const rawData = this.sitterAvailability.availabilities[this.sitterAvailabilities[i].availabilityId].rawAvailability;
        popupContent.push({
          'sitterAvailabilitiesId': i,
          'startTime': rawData.startTime,
          'endTime': rawData.endTime,
          'isAvailable': rawData.isAvailable
        });
      }
    }
    return popupContent;
  }

  setAvailabilityData(siiterAvailabilitiesId: number) {
    if (siiterAvailabilitiesId === -1) {
      this.avRepeatFlag = false;
      this.avIsWeekFlag = false;
      this.availabilityId = null;
      const date = new Date();
      this.minDateValue = {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()};
      this.availabilityForm.controls['avIsAvailable'].setValue('true');
      this.availabilityForm.controls['avStartDate'].setValue(this.availabilityCalendar.getToday());
      this.availabilityForm.controls['avEndDate'].setValue(this.availabilityCalendar.getToday());
      this.availabilityForm.controls['avStartTime'].setValue({hour: 12, minute: 0});
      this.availabilityForm.controls['avEndTime'].setValue({hour: 13, minute: 0});
      this.availabilityForm.controls['avIsRepetitive'].setValue('false');
      this.availabilityForm.controls['avRepeatOption'].setValue('every');
      this.availabilityForm.controls['avRepeatType'].setValue('day');
      this.availabilityForm.controls['avRepeatDay'].setValue('Monday');
    } else {
      const rawData = this.sitterAvailability.availabilities[this.sitterAvailabilities[siiterAvailabilitiesId].availabilityId].rawAvailability;
      let startDate = new Date(rawData.startDate);
      startDate = new Date(startDate.valueOf() + startDate.getTimezoneOffset() * 60000);
      // startDate = new Date('' + startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate());
      let endDate = new Date(rawData.endDate);
      endDate = new Date(endDate.valueOf() + endDate.getTimezoneOffset() * 60000);
      // endDate = new Date('' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate());

      this.avRepeatFlag = rawData.isRepetitive;
      this.avIsWeekFlag = (rawData.repeatType === 'week' || rawData.repeatType === null) ? true : false;
      this.availabilityForm.controls['avIsAvailable'].setValue((rawData.isAvailable) ? 'true' : 'false');
      this.availabilityForm.controls['avStartDate'].setValue({
        year: startDate.getFullYear(),
        month: (startDate.getMonth() + 1),
        day: startDate.getDate()
      });
      this.availabilityForm.controls['avEndDate'].setValue({
        year: endDate.getFullYear(),
        month: (endDate.getMonth() + 1),
        day: endDate.getDate()
      });
      const parsedStartTime = this.dateTimeHelper.timeConvertor(rawData.startTime);
      const parsedEndTime = this.dateTimeHelper.timeConvertor(rawData.endTime);
      this.availabilityForm.controls['avStartTime'].setValue({
        hour: parsedStartTime[0],
        minute: parsedStartTime[1]
      });
      this.availabilityForm.controls['avEndTime'].setValue({
        hour: parsedEndTime[0],
        minute: parsedEndTime[1]
      });
      this.availabilityForm.controls['avIsRepetitive'].setValue((rawData.isRepetitive) ? 'true' : 'false');
      this.availabilityForm.controls['avRepeatOption'].setValue((rawData.repeatOption) ? rawData.repeatOption : 'every');
      this.availabilityForm.controls['avRepeatType'].setValue((rawData.repeatType) ? rawData.repeatType : 'week');
      this.availabilityForm.controls['avRepeatDay'].setValue((rawData.repeatDay) ? rawData.repeatDay : 'Monday');
      this.availabilityId = rawData.availabilityId;
      const date = new Date(0);
      this.minDateValue = {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()};
    }
  }

  onSelectDate(date: NgbDateStruct) {
    if (date != null) {
      this.sitterAgeInfo = date;   // needed for first time around due to ngModel not binding during ngOnInit call. Seems like a bug in ng2.

    }
  }

  setDefaultDate(): NgbDateStruct {
    const startDate = new Date();
    const startYear = startDate.getFullYear().toString();
    const startMonth = startDate.getMonth() + 1;
    const startDay = '1';

    return this.ngbDateParserFormatter.parse(startYear + '-' + startMonth.toString() + '-' + startDay);
  }
}
