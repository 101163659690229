import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class OperatorGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthService) {
  }

  canActivate() {
    const localToken = this.auth.getUserRole();
    if (localToken === 'ROLE_OPERATOR') {
      return true;
    } else {
      return this.refuse();
    }
  }

  refuse(): boolean {
    this.router.navigate(['/']);
    return false;
  }
}
