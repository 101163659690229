import {LoadingSpinnerComponent} from '../modules/components/loading-spinner/loading-spinner.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalWindow} from '@ng-bootstrap/ng-bootstrap/modal/modal-window';

export class LoadingSpinnerHelper {

  private static loadingSpinner: LoadingSpinnerComponent;

  public static showLoadingSpinner(modalService: NgbModal) {
    this.loadingSpinner = new LoadingSpinnerComponent();

    modalService.open(LoadingSpinnerComponent, {
      backdrop: 'static',
      windowClass: 'my-modal-backdrop',
      centered: true,
      keyboard: false,
      size: 'lg'
    });
  }

  public static hideLoadingSpinner(modalService: NgbModal) {


    if (modalService.hasOpenModals()) {
      this.loadingSpinner = null;
      modalService.dismissAll();
    }
  }

}
