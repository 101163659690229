import {FormControl, FormGroup} from '@angular/forms';

export function checkMatchingPasswords(passwordKey: string, confirmPasswordKey: string) {
  return (group: FormGroup) => {
    if (group.controls[passwordKey].value !== group.controls[confirmPasswordKey].value) {
      group.controls[confirmPasswordKey].setErrors({mismatchedPasswords: true});
    } else {
      group.controls[confirmPasswordKey].setErrors(null);
    }
  };
}

export function checkValidAvailableTime(startTime: string, endTime: string) {
  return (group: FormGroup) => {
    if (group.controls[startTime].value === null || group.controls[endTime].value === null) {
      group.controls[endTime].setErrors({wrongValueTime: true});
    } else {
      group.controls[endTime].setErrors(null);
      if (group.controls[endTime].value.hour < group.controls[startTime].value.hour || (group.controls[endTime].value.hour === group.controls[startTime].value.hour && group.controls[endTime].value.minute < group.controls[startTime].value.minute)) {
        group.controls[endTime].setErrors({mismatchedTime: true});
      } else {
        group.controls[endTime].setErrors(null);
      }
    }
  };
}

export function checkValidAvailableDate(startDate: string, endDate: string, avIsRepetitive: string) {
  return (group: FormGroup) => {
    if (group.controls[startDate].value === null || group.controls[endDate].value === null) {
      group.controls[endDate].setErrors({wrongValueDate: true});
    } else {
      group.controls[endDate].setErrors(null);
      if (group.controls[avIsRepetitive].value === 'true' && (group.controls[endDate].value.year < group.controls[startDate].value.year ||
        (group.controls[endDate].value.year === group.controls[startDate].value.year &&
          group.controls[endDate].value.month < group.controls[startDate].value.month) ||
        (group.controls[endDate].value.year === group.controls[startDate].value.year &&
          group.controls[endDate].value.month === group.controls[startDate].value.month &&
          group.controls[endDate].value.day < group.controls[startDate].value.day))) {
        group.controls[endDate].setErrors({mismatchedDate: true});
      } else {
        group.controls[endDate].setErrors(null);
      }
    }
  };
}

export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : {'whitespace': true};
}

export function calculateAge(birthdate) {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m >= 0 && today.getDate() < birthDate.getDate())) {
    age = age + 1;
  }

  return age;

}

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
