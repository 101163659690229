import { Injectable } from '@angular/core';
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public setUserStatus(data): Observable<any> {
    return this.http.post(EndpointService.userStatus, data, AuthService.setHeader());
  }
}
