import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable} from 'rxjs';
import { EndpointService } from './endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static loggedIn = true;

  constructor(private http: HttpClient) { }

  static getSessionToken() {
    const token = localStorage.getItem('sessionToken');
    return token != null ? token : sessionStorage.getItem('sessionToken');
  }

  static setHeader() {

    return {headers: new HttpHeaders()
      .set('Authorization', 'Bearer ' + AuthService.getSessionToken())};
  }

  login(data): Observable<any> {
    return this.http.post(EndpointService.loginURL, data);
  }

  forgotPasword(data): Observable<any> {
    return this.http.post(EndpointService.forgotPassword, data);
  }


  setVariables(user) {
    sessionStorage.setItem('franchiseCity', user.franchiseCity);
    localStorage.setItem('franchiseCity', user.franchiseCity);
    sessionStorage.setItem('sessionToken', user.sessionToken);
    localStorage.setItem('sessionToken', user.sessionToken);
    sessionStorage.setItem('userRole', user.role);
    localStorage.setItem('userRole', user.role);
    sessionStorage.setItem('operatorEmail', user.email);
    localStorage.setItem('operatorEmail', user.email);
  }

  getUserRole() {
    const token = localStorage.getItem('userRole');
    return token != null ? token : sessionStorage.getItem('userRole');
  }

  getUserEmail() {
    const userEmail = localStorage.getItem('operatorEmail');
    return userEmail != null ? userEmail : sessionStorage.getItem('operatorEmail');
  }

  getFranchiseCity() {
    const franchiseCity = localStorage.getItem('franchiseCity');
    return franchiseCity !== 'null' ? franchiseCity : '';
  }

  logout() {
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('sessionToken');
    sessionStorage.removeItem('franchiseCity');
    sessionStorage.removeItem('operatorEmail');
    localStorage.removeItem('userRole');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('franchiseCity');
    localStorage.removeItem('operatorEmail');
  }

}
