import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointService } from './endpoint.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FranchisesService {

  constructor(private http: HttpClient) { }

  getOperators(): Observable<any> {
    return this.http.get(EndpointService.getAllOperators, AuthService.setHeader());
  }

  editOperator(data): Observable<any> {
    return this.http.post(EndpointService.editOperator, data, AuthService.setHeader());
  }

  changePassword(data): Observable<any> {
    return this.http.post(EndpointService.changePassword, data, AuthService.setHeader());
  }
}
