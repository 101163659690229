import {Component, OnInit} from '@angular/core';
import {FamiliesService} from '../../../core/services/families.service';
import {AppComponent} from '../../../app.component';
import {Router} from '@angular/router';
import {AuthService} from '../../../core/services/auth.service';
import {BaseFamilyInfo} from '../../../core/interfaces/models/BaseFamilyInfo';
import {GetFamiliesResponse} from '../../../core/interfaces/responses/GetFamiliesResponse';
import {HttpErrorResponse} from '@angular/common/http';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-families',
  templateUrl: './family-bills.component.html',
  styleUrls: ['./family-bills.component.scss']
})

export class FamilyBillsComponent implements OnInit {

  private families: Array<BaseFamilyInfo> = new Array<BaseFamilyInfo>();
  private searchedFamilies: Array<BaseFamilyInfo> = new Array<BaseFamilyInfo>();
  protected searchString: string;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private familiesService: FamiliesService,
              private router: Router,
              private auth: AuthService,
              private app: AppComponent,
              private modalService: NgbModal) {

    this.searchString = '';
  }

  ngOnInit() {
    this.getFamilies();
  }

  /**
   * Marker: Retrieve Families
   * =============================================================================================
   */

  private getFamilies() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.familiesService.getFamiliesForBilling().subscribe(
      (response: GetFamiliesResponse) => {
        this.onGetFamiliesSuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onGetFamiliesFailed(error);
      }
    );
  }

  private onGetFamiliesSuccess(response: GetFamiliesResponse) {
    if (response.success) {
      this.families = response.families;
      this.searchedFamilies = response.families;
    }
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetFamiliesFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Search Families
   * =============================================================================================
   */

  private getFamiliesByCriteria() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);
    this.searchedFamilies = [];
    this.searchString = this.searchString.toLowerCase();
    for (let i = 0; i < this.families.length; i++) {
      if (this.families[i].clientFirstName.toLowerCase().indexOf(this.searchString) !== -1 || this.families[i].familyName.toLowerCase().indexOf(this.searchString) !== -1 || (this.families[i].clientFirstName.toLowerCase() + this.families[i].familyName.toLowerCase()).indexOf(this.searchString) !== -1) {
        this.searchedFamilies.push(this.families[i]);
      }
    }
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

  }

}
