import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor() {
  }

  // dev server
  public static apiURL = 'http://18.217.235.9:8080/NeedASitter/API/';

  // testing server
  // public static apiURL = 'http://3.16.103.64:8080/NeedASitter/API/';

  // prod server
  // public static apiURL = 'https://api.rosyapp.com/';

  // local server
  // public static apiURL = 'http://192.168.0.168:8080/NeedASitter/API/';

  public static addOperatorUrl: string = EndpointService.apiURL + 'admin/addOperator';

  public static getAllOperators: string = EndpointService.apiURL + 'admin/getAllOperators';

  public static editOperator: string = EndpointService.apiURL + 'admin/editOperator';

  public static loginURL: string = EndpointService.apiURL + 'users/login';

  public static userStatus: string = EndpointService.apiURL + 'users/setUserStatus';

  public static changePassword: string = EndpointService.apiURL + 'users/changePassword';

  public static forgotPassword: string = EndpointService.apiURL + 'users/forgotPassword';

  public static addSitterUrl: string = EndpointService.apiURL + 'sitters/addSitter';

  public static getSitters: string = EndpointService.apiURL + 'sitters/getSitters';

  public static getSitterById: string = EndpointService.apiURL + 'sitters/getSitterById';

  public static editSitterContactInfo: string = EndpointService.apiURL + 'sitters/setContactInfo';

  public static editSitterAboutInfo: string = EndpointService.apiURL + 'sitters/setPersonalInfo';

  public static getSitterNotes: string = EndpointService.apiURL + 'operators/getSitterNotes';

  public static searchSitters: string = EndpointService.apiURL + 'sitters/searchSitters';

  public static getSitterFavoritFamilies: string = EndpointService.apiURL + 'sitters/getFavoriteFamilies';

  public static getSitterAvailability: string = EndpointService.apiURL + 'sitters/getAvailability';

  public static addSitterAvailability: string = EndpointService.apiURL + 'sitters/setAvailability';

  public static editSitterAvailability: string = EndpointService.apiURL + 'sitters/updateAvailability';

  public static removeAvailability: string = EndpointService.apiURL + 'sitters/removeAvailability';

  public static getSittersForJobAssigning: string = EndpointService.apiURL + 'sitters/getSittersForJobAssigning';

  public static searchSittersForJobAssigning: string = EndpointService.apiURL + 'sitters/searchSittersForJobAssigning';

  public static addSitterNote: string = EndpointService.apiURL + 'operators/addNote';

  public static getSitterJobs: string = EndpointService.apiURL + 'jobs/getJobsForSitter';

  public static getFamilyJobs: string = EndpointService.apiURL + 'jobs/getJobsForFamily';

  public static getFamiliesForBilling: string = EndpointService.apiURL + 'families/getFamiliesForBilling';

  public static addJob: string = EndpointService.apiURL + 'jobs/addJob';

  public static editJobDetails: string = EndpointService.apiURL + 'jobs/editJobDetails';

  public static getAllJobs: string = EndpointService.apiURL + 'jobs/getAllJobs';

  public static getAdditionalDetailsForJob: string = EndpointService.apiURL + 'jobs/getJobAdditionalDetails';

  public static changeJobStatus: string = EndpointService.apiURL + 'jobs/changeJobStatus';

  public static assignSitter: string = EndpointService.apiURL + 'jobs/assignSitter';

  public static getFamilies: string = EndpointService.apiURL + 'families/getFamilies';

  public static searchFamilies: string = EndpointService.apiURL + 'families/searchFamilies';

  public static getFamilyById: string = EndpointService.apiURL + 'families/getFamilyById';

  public static getFamilyNotes: string = EndpointService.apiURL + 'operators/getFamilyNotes';

  public static addFamilyNote: string = EndpointService.apiURL + 'operators/addNote';

  public static getFeesForFranchise: string = EndpointService.apiURL + 'operators/getFeesForFranchise';

  public static addFamilyChild: string = EndpointService.apiURL + 'families/addChild';

  public static addFamilyUrl: string = EndpointService.apiURL + 'families/addFamily';

  public static addEmergencyContact: string = EndpointService.apiURL + 'families/addEmergencyContact';

  public static getFavoriteSitters: string = EndpointService.apiURL + 'families/getFavoriteSitters';

  public static editAdditionalDetails: string = EndpointService.apiURL + 'families/editAdditionalDetails';

  public static sendNotificationToSitter: string = EndpointService.apiURL + 'operators/sendNotification';

  public static addToFavorites: string = EndpointService.apiURL + 'operators/addFavorite';

  public static removeFromFavorites: string = EndpointService.apiURL + 'operators/removeFavorite';

  public static editCompletedJobComment: string = EndpointService.apiURL + 'operators/addCommentForCompletedJob';

  public static getPaymentsForFamily: string = EndpointService.apiURL + 'payments/getUnbilledPaymentsForFamily';

  public static getBillsForFamily: string = EndpointService.apiURL + 'payments/getBillsForFamily';

  public static addBill: string = EndpointService.apiURL + 'payments/addBill';

}
