///<reference path="../../../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import {
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import {DashboardService} from '../../../core/services/dashboard.service';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import {HttpErrorResponse} from '@angular/common/http';
import {GetFamilyDetailsResponse} from '../../../core/interfaces/responses/GetFamilyDetailsResponse';
import {GetByIdRequest} from '../../../core/interfaces/requests/GetByIdRequest';
import {FamiliesService} from '../../../core/services/families.service';
import {CompleteFamilyInfo} from '../../../core/interfaces/models/CompleteFamilyInfo';
import {FamilyChild} from '../../../core/interfaces/models/FamilyChild';
import {GetJobAdditionalInfoResponse} from '../../../core/interfaces/responses/GetJobAdditionalInfoResponse';
import {GetFamilyJobsResponse} from '../../../core/interfaces/responses/GetFamilyJobsResponse';
import * as $ from 'jquery';
import {DateTimeHelper} from '../../../helpers/date-time-helper';
import {EditCommentForCompletedJobRequest} from '../../../core/interfaces/requests/EditCommentForCompletedJobRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';

@Component({
  selector: 'app-dashboard-details-page',
  templateUrl: './dashboard-details-page.component.html',
  styleUrls: ['./dashboard-details-page.component.scss']
})
export class DashboardDetailsPageComponent implements OnInit, OnChanges {
  @Input() job: JobInfo;
  @Input() openedSubmenu: string;
  @Output() closeSubmenuLocal = new EventEmitter();

  protected children: Array<FamilyChild>;
  protected requestToOperator: string;
  protected notestForSitter: string;
  protected completedJobComment: string;
  protected familyUpcomingBookings: Array<JobInfo>;
  protected familyCompletedBookings: Array<JobInfo>;
  protected familyDetails: CompleteFamilyInfo;
  protected selectedSitterId: number;
  protected selectedSitterName: string;
  protected dateTimeHelper: DateTimeHelper;


  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private familiesService: FamiliesService, private dashboardService: DashboardService,
              private modalService: NgbModal) {
    this.selectedSitterId = null;
    this.selectedSitterName = '';
    this.dateTimeHelper = new DateTimeHelper();
    this.children = new Array<FamilyChild>();

  }

  ngOnInit() {
    this.familyUpcomingBookings = new Array<JobInfo>();
    this.familyCompletedBookings = new Array<JobInfo>();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.job && simpleChanges.job.currentValue) {
      this.changeAssigningButton(this.selectedSitterId, this.selectedSitterName);
      this.getFamilyDetails(simpleChanges.job.currentValue.familyId);
      this.getFamilyJobs(simpleChanges.job.currentValue.familyId);
    }
  }

  /**
   * Marker: Jobs
   * =============================================================================================
   */


  private getFamilyJobs(id: number) {
    const params: GetByIdRequest = {id: id};

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.familiesService.getFamilyJobs(params)
      .subscribe((response: GetFamilyJobsResponse) => {
        this.onGetFamilyJobsSuccess(response);
      }, (error) => {
        this.onGetFamilyJobsFailed(error);
      });
  }

  private onGetFamilyJobsSuccess(response: GetFamilyJobsResponse) {
    if (response.success) {
      this.familyCompletedBookings = response.completedJobs;
      this.familyUpcomingBookings = response.upcomingJobs;

    } else {
      console.error('got error: ' + response.message);
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetFamilyJobsFailed(error: HttpErrorResponse) {
    console.error(error);
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }


  /**
   * Marker: Family Details
   * =============================================================================================
   */

  private getFamilyDetails(id: number) {

    const params: GetByIdRequest = {id: id};

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.familiesService.getFamilyDetails(params)
      .subscribe((response: GetFamilyDetailsResponse) => {
          this.onGetFamilyDetailsSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetFamilyDetailsFailed(error);
        });
  }

  private onGetFamilyDetailsSuccess(response: GetFamilyDetailsResponse) {
    this.familyDetails = response.family;
    const id = this.job.jobId;

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

    this.getJobAdditionalInfo(id);
  }

  private onGetFamilyDetailsFailed(error: HttpErrorResponse) {
    console.error(error);
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Job Additional Info
   * =============================================================================================
   */

  private getJobAdditionalInfo(jobId: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params = {
      'id': jobId
    };

    this.dashboardService.getAdditionalDetailsForJob(params)
      .subscribe((response: GetJobAdditionalInfoResponse) => {
          this.onGetAdditionlInfoSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetAdditionaInfoFailed(error);
        });
  }


  private onGetAdditionlInfoSuccess(response: GetJobAdditionalInfoResponse) {
    if (response.success) {
      this.children = response.additionalDetails.children;
      this.notestForSitter = response.additionalDetails.notesForSitter;
      this.requestToOperator = response.additionalDetails.requestToOperator;
      this.completedJobComment = response.additionalDetails.completedJobComment;
      // this.familyDetails.children = this.children;

      for (let i = 0; i < this.familyDetails.children.length; i++) {
        this.familyDetails.children[i].zoomed = false;
      }

    } else {
      console.error('additional details error: ' + response.message);
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetAdditionaInfoFailed(error: HttpErrorResponse) {
    console.error('additional details error: ' + error);
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  protected editOperatorObservations() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const data: EditCommentForCompletedJobRequest = {
      jobId: this.job.jobId,
      comment: this.completedJobComment
    };

    this.dashboardService.editCommentForCompletedJob(data)
      .subscribe((response: BaseResponse) => {
          this.onEditCommentForCompletedJobSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onEditCommentForCompletedJobFailed(error);
        });
  }

  private onEditCommentForCompletedJobSuccess(response: BaseResponse) {
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

    if (response.success === false) {
      alert(response.message);
    } else {

    }

  }

  private onEditCommentForCompletedJobFailed(error: HttpErrorResponse) {
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    alert(error.message);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  private changeAssigningButton(sitterId: number, siterName: string) {
    if (this.selectedSitterId === sitterId) {
      this.selectedSitterId = null;
      this.selectedSitterName = '';
    } else {
      this.selectedSitterId = sitterId;
      this.selectedSitterName = siterName;
    }
  }

  closeSubmenu(submenuId: string) {
    $('.sidebar').toggleClass('submenu-close');
    this.closeSubmenuLocal.emit([submenuId]);
  }
}
