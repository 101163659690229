import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './modules/pages/dashboard/dashboard.component';
import {FamiliesComponent} from './modules/pages/families/families.component';
import {FranchisesComponent} from './modules/pages/franchises/franchises.component';
import {OperationsComponent} from './modules/pages/operations/operations.component';
import {ReportsComponent} from './modules/pages/reports/reports.component';
import {SittersComponent} from './modules/pages/sitters/sitters.component';
import {SitterDetailsComponent} from './modules/pages/sitter-details/sitter-details.component';
import {LoginComponent} from './modules/pages/login/login.component';
import {AdminGuard} from './core/guards/admin.guard';
import {OperatorGuard} from './core/guards/operator.guard';
import {LoggedGuard} from './core/guards/logged.guard';
import {FamilyDetailsComponent} from './modules/pages/family-details/family-details.component';
import {FamilyBillsComponent} from './modules/pages/family-bills/family-bills.component';
import {FamilyPaymentsComponent} from './modules/pages/family-payments/family-payments.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'franchises', component: FranchisesComponent, canActivate: [LoggedGuard, AdminGuard]},
  {path: 'reports', component: ReportsComponent, canActivate: [LoggedGuard, AdminGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: 'families', component: FamiliesComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: 'operations', component: OperationsComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: 'sitters', component: SittersComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: 'sitter-details/:id', component: SitterDetailsComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: 'family-details/:id', component: FamilyDetailsComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: 'family-bills', component: FamilyBillsComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: 'family-payments/:id/:name', component: FamilyPaymentsComponent, canActivate: [LoggedGuard, OperatorGuard]},
  {path: '**', redirectTo: 'login'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule {
}
