import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ERROR_MESSAGES} from '../../../helpers/errors';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FamiliesService} from '../../../core/services/families.service';
import {checkMatchingPasswords, noWhitespaceValidator} from '../../../core/shared/utils';
import {emailValidator, nameValidator, passwordValidator, phoneValidator} from '../../../helpers/regex';
import {HttpErrorResponse} from '@angular/common/http';
import {AddFamilyRequest} from '../../../core/interfaces/requests/AddFamilyRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';

@Component({
  selector: 'app-add-families',
  templateUrl: './add-families.component.html',
  styleUrls: ['./add-families.component.scss']
})
export class AddFamiliesComponent implements OnInit {

  @Output() duplicateEmailError = new EventEmitter();

  protected errorMessages = ERROR_MESSAGES;
  protected receivedErrorMessage: string;

  protected familyForm: FormGroup;
  protected viewPasswordFamily: boolean;
  protected viewPasswordConfirmFamily: boolean;

  protected isAddingUser: boolean;
  protected userAdded: boolean;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private formBuilder: FormBuilder, private elementRef: ElementRef,
              private familyService: FamiliesService) {
  }

  ngOnInit() {
    this.viewPasswordFamily = false;
    this.viewPasswordConfirmFamily = false;
    this.initFlags();
    this.initFamilyForm();
  }

  private initFlags() {
    this.isAddingUser = false;
    this.userAdded = false;
  }

  private initFamilyForm() {

    this.familyForm = this.formBuilder.group({
      firstName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      lastName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      email: ['', [Validators.required, emailValidator, noWhitespaceValidator]],
      password: ['', [Validators.required, passwordValidator, noWhitespaceValidator]],
      repeatPassword: ['', [Validators.required, passwordValidator, noWhitespaceValidator]],
      address: ['', [Validators.required, noWhitespaceValidator]],
      city: ['', [Validators.required, noWhitespaceValidator]],
      state: ['', [Validators.required, noWhitespaceValidator]],
      country: ['', [Validators.required, noWhitespaceValidator]],
      zipCode: ['', [Validators.required, noWhitespaceValidator]],
      phoneNumber: ['', [Validators.required, phoneValidator, noWhitespaceValidator]],
      emailText: ['', []],
      familyNotes: ['', []]
    }, {
      validator: checkMatchingPasswords('password', 'repeatPassword')
    });

  }

  /**
   * Marker: Add Family
   * =============================================================================================
   */

  private getNewFamily(): AddFamilyRequest {

    const params: AddFamilyRequest = {
      byOperator: sessionStorage.getItem('operatorEmail'),
      family: {
        user: {
          firstName: this.familyForm.controls['firstName'].value,
          lastName: this.familyForm.controls['lastName'].value,
          email: this.familyForm.controls['email'].value,
          password: this.familyForm.controls['password'].value,
          phoneNumber: this.familyForm.controls['phoneNumber'].value,
        },
        familyNotes: this.familyForm.controls['familyNotes'].value,
        address: this.familyForm.controls['address'].value,
        city: this.familyForm.controls['city'].value,
        state: this.familyForm.controls['state'].value,
        country: this.familyForm.controls['country'].value,
        zipCode: this.familyForm.controls['zipCode'].value
      },
      emailText: this.familyForm.controls['emailText'].value
    };
    return params;
  }


  private createFamily() {
    this.isAddingUser = true;
    this.familyService.addFamily(this.getNewFamily()).subscribe(
      (response: BaseResponse) => {
        this.onAddFamilySuccess(response);
      },
      (error: HttpErrorResponse) => {
        this.onAddFamilyFailed(error);
      }
    );
  }

  private onAddFamilySuccess(response: BaseResponse) {

    if (response.success) {
      this.userAdded = true;
    } else {
      this.userAdded = false;
      this.receivedErrorMessage = response.message;
      if (this.receivedErrorMessage === ERROR_MESSAGES.DUPLICATE_EMAIL_ERROR_MESSAGE) {
        this.duplicateEmailError.emit();
      }
      // switch (response.message) {
      //   case ERROR_MESSAGES.DUPLICATE_EMAIL_ERROR_MESSAGE:
      //     this.errorMessageFamily = this.errorMessages.DUPLICATE_EMAIL_ERROR_MESSAGE;
      //     this.elementRef.nativeElement.querySelector('#email').scrollIntoView();
      //     break;
      //   default:
      //     this.errorMessageFamily = ERROR_MESSAGES.GENERIC_ERROR_MESSAGE;
      //     break;
      // }
    }

    this.isAddingUser = false;


  }

  private onAddFamilyFailed(error: HttpErrorResponse) {
    console.log(error);
    this.userAdded = false;
    this.isAddingUser = false;
  }
}
