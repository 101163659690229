import { Component, OnInit } from '@angular/core';
import {AuthService} from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  role;
  loggedIn = false;

  constructor( private auth: AuthService) {}

  ngOnInit() {
    this.role = this.auth.getUserRole();
    // this.loggedIn = this.auth.checkIfLoggedIn();
  }

  setLoggedIn(loggedIn) {
    this.role = loggedIn;
    console.warn('user role set to ' + this.role);
  }
}
