import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import {FamilyChild} from '../../../core/interfaces/models/FamilyChild';
import {DashboardService} from '../../../core/services/dashboard.service';
import {DateTimeHelper} from '../../../helpers/date-time-helper';
import * as $ from 'jquery';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeJobStatusRequest} from '../../../core/interfaces/requests/ChangeJobStatusRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {AssignSitterRequest} from '../../../core/interfaces/requests/AssignSitterRequest';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {checkValidAvailableDate, checkValidAvailableTime} from '../../../core/shared/utils';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EditJobRequest} from '../../../core/interfaces/requests/EditJobRequest';

@Component({
  selector: 'app-job-main-details',
  templateUrl: './job-main-details.component.html',
  styleUrls: ['./job-main-details.component.scss']
})
export class JobMainDetailsComponent implements OnInit, OnChanges {

  @Input() jobInfo: JobInfo;
  @Input() children: Array<FamilyChild>;
  @Input() allChildren: Array<FamilyChild>;
  @Input() requestToOperator: string;
  @Input() notesForSitter: string;
  @Input() selectedSitterId: number;
  @Input() selectedSitterName: string;
  @Output() closeSubmenuLocal = new EventEmitter();

  dateTimeHelper: DateTimeHelper;
  protected jobDeclineReason: string;
  protected emptyDeclineReason: boolean;
  protected editJobForm: FormGroup;
  protected startDate: NgbDateStruct;
  protected  minDateValue: object;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private dashboardService: DashboardService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private jobCalendar: NgbCalendar, private ngbDateParserFormatter: NgbDateParserFormatter) {
    this.dateTimeHelper = new DateTimeHelper();
    this.jobDeclineReason = '';
    this.emptyDeclineReason = false;
    const date = new Date();
    this.minDateValue = {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()};
  }

  ngOnInit() {
    this.startDate = this.setDefaultDate();
    this.onSelectDate(this.startDate);
    $('#edit-job-icon').hide();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.jobInfo) {

      $('.favorites-sitters').hide();
      $('#actionButton').removeClass('assigning');
      $('#edit-job-icon').hide();
      this.getFirstButtonText();

    }

    if (changes.children) {
      $('#edit-job-icon').show();
      this.initEditJobForm();
    }

  }

  initEditJobForm() {
    const date = new Date(this.jobInfo.date);
    this.editJobForm = this.formBuilder.group({
      jobStartDate: [{year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}, [Validators.required]],
      jobChildrens: ['', [Validators.required]],
      jobAddress: ['', [Validators.required]],
      jobCity: ['', [Validators.required]],
      jobState: ['', [Validators.required]],
      jobCountry: ['', [Validators.required]],
      jobZipCode: ['', [Validators.required]],
      jobStartTime: [{hour: 12, minute: 0}, [Validators.required]],
      jobEndTime: [{hour: 13, minute: 0}, [Validators.required]],
      jobNotesToSitter: [this.requestToOperator, []],
      jobRequestForOperator: [this.notesForSitter, []]
    }, {
      validator: [
        checkValidAvailableTime('jobStartTime', 'jobEndTime')
      ]
    });
  }

  /**
   * Marker: Change Job Status
   * =============================================================================================
   */

  private changeJobStatus(buttonType: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    let newJobStatus = '';
    if (buttonType === 1) {
      switch (this.jobInfo.jobStatus) {
        case 'unassigned':
          this.assignSitter();
          break;
        case 'declined':
          this.assignSitter();
          break;
        case 'pending':
          newJobStatus = 'upcoming';
          break;
      }
    } else {
      switch (this.jobInfo.jobStatus) {
        case 'unassigned':
          newJobStatus = 'canceled';
          break;
        case 'pending':
          newJobStatus = 'declined';
          break;
        case 'upcoming':
          newJobStatus = 'canceled';
          break;
      }
    }
    if (buttonType === 2 || this.jobInfo.jobStatus !== 'unassigned' && this.jobInfo.jobStatus !== 'declined') {
      const params: ChangeJobStatusRequest = {
        jobId: this.jobInfo.jobId,
        jobStatus: newJobStatus
      };

      if (buttonType === 2 && this.jobInfo.jobStatus === 'pending') {
        if (this.jobDeclineReason == null || this.jobDeclineReason.length === 0 || this.jobDeclineReason.replace(/^\s+/, '').replace(/\s+$/, '') === '') {
          this.emptyDeclineReason = true;
          LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
          return;
        } else {
          params.declineReason = this.jobDeclineReason;
        }
      }
      this.dashboardService.changeJobStatus(params)
        .subscribe((response: BaseResponse) => {
            LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
            location.reload();
          },
          (error: HttpErrorResponse) => {
            this.onChangeJobStatusFailed(error);
          });
    }
  }

  private onChangeJobStatusFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private assignSitter() {

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

    if ($('#actionButton').hasClass('assigning')) {
      const params: AssignSitterRequest = {
        sitterId: this.selectedSitterId,
        jobId: this.jobInfo.jobId
      };
      this.dashboardService.assignSitter(params)
        .subscribe((response: BaseResponse) => {
            location.reload();

          },
          (error: HttpErrorResponse) => {
            this.onAssignSitterFailed(error);
          });
    }
  }

  private onAssignSitterFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Edit Job
   * =============================================================================================
   */

  private editJob() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    if (this.editJobForm.valid) {
      const dateDefault = new Date();
      const startDate = new Date(Date.UTC(this.editJobForm.controls['jobStartDate'].value.year, (this.editJobForm.controls['jobStartDate'].value.month - 1), this.editJobForm.controls['jobStartDate'].value.day));

      const childrenIds = [];
      for (let i = 0; i < this.allChildren.length; i++) {
        if (this.allChildren[i].zoomed) {
          childrenIds.push(this.allChildren[i].childId);
        }
      }

      const params: EditJobRequest = {

        childrenIds: childrenIds,
        job: {
          jobId: this.jobInfo.jobId,
          jobDate: (this.editJobForm.controls['jobStartDate'].value === null) ? dateDefault : startDate,
          startTime: this.editJobForm.controls['jobStartTime'].value.hour + ':' + this.editJobForm.controls['jobStartTime'].value.minute,
          endTime: this.editJobForm.controls['jobEndTime'].value.hour + ':' + this.editJobForm.controls['jobEndTime'].value.minute,
          address: this.editJobForm.controls['jobAddress'].value,
          city: this.editJobForm.controls['jobCity'].value,
          state: this.editJobForm.controls['jobState'].value,
          country: this.editJobForm.controls['jobCountry'].value,
          zipCode: this.editJobForm.controls['jobZipCode'].value,
          notesToSitter: this.editJobForm.controls['jobNotesToSitter'].value,
          requestForOperator: this.editJobForm.controls['jobRequestForOperator'].value
        }
      };

      this.dashboardService.editJobDetails(params)
        .subscribe((response: BaseResponse) => {
            this.onEditJobSuccess(response);
          },
          (error: HttpErrorResponse) => {
            this.onEditJobFailed(error);
          });
    }

  }

  private onEditJobSuccess(response: BaseResponse) {
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    if (response.success) {
      location.reload();
    } else {
      alert(response.message);
    }
  }

  private onEditJobFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  protected showFavoritSitters() {
    if ($('#actionButton').hasClass('unassigned') || $('#actionButton').hasClass('declined')) {
      $('.favorites-sitters').show();
    }
  }


  protected showFirstButton(): boolean {
    return this.jobInfo.jobStatus === 'unassigned' || this.jobInfo.jobStatus === 'declined' ||
      this.jobInfo.jobStatus === 'pending';
  }

  protected showSecondButton(): boolean {
    return this.jobInfo.jobStatus === 'unassigned' || this.jobInfo.jobStatus === 'pending' ||
      this.jobInfo.jobStatus === 'upcoming';
  }

  protected getFirstButtonText(): string {
    if (this.selectedSitterName !== '') {
      switch (this.jobInfo.jobStatus) {
        case 'unassigned':
          return 'ASSIGN ' + this.selectedSitterName.toUpperCase() + ' TO JOB';
        case 'pending':
          return 'CONFIRM ' + this.selectedSitterName.toUpperCase() + ' FOR JOB';
        case 'declined':
          return 'REASSIGN ' + this.selectedSitterName.toUpperCase() + ' TO JOB';
      }
    } else {
      switch (this.jobInfo.jobStatus) {
        case 'unassigned':
          return 'ASSIGN A SITTER';
        case 'pending':
          return 'CONFIRM JOB';
        case 'declined':
          return 'REASSIGN JOB';
      }
    }
  }

  protected getSecondButtonText(): string {

    switch (this.jobInfo.jobStatus) {
      case 'unassigned':
        return 'CANCEL BOOKING';
      case 'pending':
        return 'DECLINE JOB';
      case 'upcoming':
        return 'CANCEL BOOKING';
    }
  }

  protected checkValidChildrens() {
    console.log(this.allChildren);
    console.log(this.children);
    let checkValid = false;
    if (this.allChildren) {
      for (let i = 0; i < this.allChildren.length; i++) {
        if (this.allChildren[i].zoomed) {
          checkValid = true;
          break;
        }
      }
    }
    if (checkValid) {
      this.editJobForm.controls['jobChildrens'].setErrors(null);
    } else {
      this.editJobForm.controls['jobChildrens'].setErrors({wrongValueMultipleCheckbox: true});
    }
  }

  clearEditJob() {

    for (let i = 0; i < this.allChildren.length; i++) {
      this.allChildren[i].zoomed = false;
      for (let j = 0; j < this.children.length; j++) {
        if (this.allChildren[i].childId === this.children[j].childId) {
          this.allChildren[i].zoomed = true;
        }
      }
    }
    let date = new Date(this.jobInfo.date);
    date = new Date(date.valueOf() + date.getTimezoneOffset() * 60000);
    const parsedStartTime = this.dateTimeHelper.timeConvertor(this.jobInfo.startTime);
    const parsedEndTime = this.dateTimeHelper.timeConvertor(this.jobInfo.endTime);
    this.editJobForm = this.formBuilder.group({
      jobStartDate: [{year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}, [Validators.required]],
      jobChildrens: ['', []],
      jobAddress: [this.jobInfo.address, [Validators.required]],
      jobCity: [this.jobInfo.city, [Validators.required]],
      jobState: [this.jobInfo.state, [Validators.required]],
      jobCountry: [this.jobInfo.country, [Validators.required]],
      jobZipCode: [this.jobInfo.zipCode, [Validators.required]],
      jobStartTime: [{
        hour: parsedStartTime[0],
        minute: parsedStartTime[1]
      }, [Validators.required]],
      jobEndTime: [{
        hour: parsedEndTime[0],
        minute: parsedEndTime[1]
      }, [Validators.required]],
      jobNotesToSitter: [this.requestToOperator, []],
      jobRequestForOperator: [this.notesForSitter, []]
    }, {
      validator: [
        checkValidAvailableTime('jobStartTime', 'jobEndTime')
      ]
    });
    this.checkValidChildrens();
  }

  protected toggleActive($event, index: number) {
    $event.stopPropagation();
    this.allChildren[index].zoomed = !this.allChildren[index].zoomed;
    this.checkValidChildrens();
  }

  protected onSelectDate(date: NgbDateStruct) {
    if (date != null) {
      this.startDate = date;
    }
  }

  protected setDefaultDate(): NgbDateStruct {

    const startDate = new Date(this.jobInfo.date);

    const startYear = startDate.getFullYear().toString();
    const startMonth = startDate.getMonth() + 1;
    const startDay = '1';

    return this.ngbDateParserFormatter.parse(startYear + '-' + startMonth.toString() + '-' + startDay);
  }

}
