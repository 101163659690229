///<reference path="../../../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {DashboardService} from '../../../core/services/dashboard.service';
import * as $ from 'jquery';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import {dayNames, monthNames} from '../../../core/shared/utils';
import {SendNotificationToSitterRequest} from '../../../core/interfaces/requests/SendNotificationToSitterRequest';
import {HttpErrorResponse} from '@angular/common/http';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {DateTimeHelper} from '../../../helpers/date-time-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';


@Component({
  selector: 'app-dashboard-submenu',
  templateUrl: './dashboard-submenu.component.html',
  styleUrls: ['./dashboard-submenu.component.scss']
})
export class DashboardSubmenuComponent implements OnInit, OnChanges {
  @Input() openedSubmenu: string;
  @Input() allJobs;
  @Output() closeSubmenuLocal = new EventEmitter();
  @Output() openDetailsPageLocal = new EventEmitter();

  protected currentDate: Date;
  protected submenuTitle: string;
  protected jobs: Array<JobInfo>;
  protected dateTimeHelper: DateTimeHelper;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private elementRef: ElementRef,
              private dashboardService: DashboardService,
              private modalService: NgbModal) {
    this.dateTimeHelper = new DateTimeHelper();

  }

  ngOnInit() {
    this.currentDate = new Date(0);
    this.submenuTitle = '';
    this.jobs = new Array<JobInfo>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openedSubmenu) {
      this.currentDate = new Date(0);
      const openedSubmenu: SimpleChange = changes.openedSubmenu;
      switch (openedSubmenu.currentValue) {
        case 'upcomingSubmenu':
          this.submenuTitle = 'UPCOMING JOBS';
          this.constructJobsList(this.allJobs.upcomingJobs);
          break;
        case 'unassignedSubmenu':
          this.submenuTitle = 'UNASSIGNED JOBS';
          this.constructJobsList(this.allJobs.unassignedJobs);
          break;
        case 'pendingSubmenu':
          this.submenuTitle = 'PENDING JOBS';
          this.constructJobsList(this.allJobs.pendingJobs);
          break;
        case 'declinedSubmenu':
          this.submenuTitle = 'DECLINED JOBS';
          this.constructJobsList(this.allJobs.declinedJobs);
          break;
        case 'cancelledSubmenu':
          this.submenuTitle = 'CANCELLED JOBS';
          this.constructJobsList(this.allJobs.canceledJobs);
          break;
      }
    }
    if (changes.allJobs) {
      this.currentDate = new Date(0);
      switch (this.openedSubmenu) {
        case 'upcomingSubmenu':
          this.submenuTitle = 'UPCOMING JOBS';
          this.constructJobsList(this.allJobs.upcomingJobs);
          break;
        case 'unassignedSubmenu':
          this.submenuTitle = 'UNASSIGNED JOBS';
          this.constructJobsList(this.allJobs.unassignedJobs);
          break;
        case 'pendingSubmenu':
          this.submenuTitle = 'PENDING JOBS';
          this.constructJobsList(this.allJobs.pendingJobs);
          break;
        case 'declinedSubmenu':
          this.submenuTitle = 'DECLINED JOBS';
          this.constructJobsList(this.allJobs.declinedJobs);
          break;
        case 'cancelledSubmenu':
          this.submenuTitle = 'CANCELLED JOBS';
          this.constructJobsList(this.allJobs.canceledJobs);
          break;
      }
    }
  }

  /**
   * Marker: Send Notifications to Sitter
   * =============================================================================================
   */

  notifySitter(type: number, sitterId: number, jobId: number) {

    if ($('#' + jobId).hasClass('notification-sent-button') === true) {
      return;

    }

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const data: SendNotificationToSitterRequest = {
      notificationType: type,
      jobId: jobId,
      sitterId: sitterId
    };

    this.dashboardService.sendNotificationToSitter(data)
      .subscribe((response: BaseResponse) => {
          this.onNotifiSitterSuccess(response, jobId);
        },
        (errorResponse: HttpErrorResponse) => {
          this.onNotifySitterFailed(errorResponse);
        });

  }

  changeButtonClass(jobId: number) {

    $('#' + jobId).toggleClass('notification-sent-button');

  }

  private onNotifiSitterSuccess(response: BaseResponse, jobId: number) {
    console.log('notif response is: ', response);
    if (response.success === true) {
      this.changeButtonClass(jobId);
    } else {

      alert(response.message);

      // // Testing only
      // this.changeButtonClass(jobId);
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onNotifySitterFailed(errorResponse: HttpErrorResponse) {
    console.error('notify sitter error response: ', errorResponse);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  protected constructJobsList(jobsArray: Array<JobInfo>) {
    this.jobs = [];
    for (let i = 0; i < jobsArray.length; i++) {
      jobsArray[i].hasTitle =  this.compareDates(this.currentDate, jobsArray[i].date);
      this.jobs.push(jobsArray[i]);
    }
  }

  compareDates(currentDate: Date, newDate: Date) {
    const firstDate = new Date(currentDate);
    const secondDate = new Date(newDate);
    if (firstDate.getFullYear() !== secondDate.getFullYear() || (firstDate.getFullYear() === secondDate.getFullYear() && firstDate.getMonth() !== secondDate.getMonth() || (firstDate.getFullYear() === secondDate.getFullYear() && firstDate.getMonth() === secondDate.getMonth() && firstDate.getDate() !== secondDate.getDate()))) {
      this.currentDate = newDate;
      return true;
    }
    return false;
  }

  closeSubmenu(submenuId: string) {
    $('.sidebar').toggleClass('submenu-close');
    this.closeSubmenuLocal.emit([submenuId]);
  }


  openDetailsPage(jobId: number, jobStatus: string, jobDate: Date) {
    $('.sidebar').toggleClass('submenu-close');
    this.openDetailsPageLocal.emit([jobId, jobStatus, jobDate]);
  }
}
