import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FavoriteSitter} from '../../../core/interfaces/models/FavoriteSitter';
import {GetFavoriteSittersResponse} from '../../../core/interfaces/responses/GetFavoriteSittersResponse';
import {GetByIdRequest} from '../../../core/interfaces/requests/GetByIdRequest';
import {HttpErrorResponse} from '@angular/common/http';
import {FamiliesService} from '../../../core/services/families.service';
import {SittersService} from '../../../core/services/sitters.service';
import {GetFavoriteFamiliesResponse} from '../../../core/interfaces/responses/GetFavoriteFamiliesResponse';
import {FavoriteFamily} from '../../../core/interfaces/models/FavoriteFamily';
import {GetSittersForAssigningRequest} from '../../../core/interfaces/requests/GetSittersForAssigningRequest';
import {GetSitersForAssigningResponse} from '../../../core/interfaces/responses/GetSitersForAssigningResponse';
import {dayNames} from '../../../core/shared/utils';
import {BaseSitterInfo} from '../../../core/interfaces/models/BaseSitterInfo';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import * as $ from 'jquery';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit, OnChanges {

  @Input() familyId;
  @Input() sitterId;
  @Input() userName;
  @Input() jobDate;
  @Input() jobStartTime;
  @Input() jobEndTime;
  @Input() showSearchArea;
  @Input() jobId;
  @Output() changeAssigningButton = new EventEmitter();

  protected favoriteSitters: Array<FavoriteSitter>;
  protected favoriteFamilies: Array<FavoriteFamily>;
  protected sittersForJob: Array<any>;
  protected searchString: string;
  protected wrongDayNameFlag: boolean;
  protected showMoreButtonDisplayed: boolean;
  protected totalNumberOfSitters: number;
  protected selectedSitterId: number;
  protected selectedSitterName: string;

  constructor(private familiesService: FamiliesService, private sittersService: SittersService,
              private modalService: NgbModal) {
    this.searchString = '';
    this.wrongDayNameFlag = false;
    this.selectedSitterId = null;
    this.selectedSitterName = null;
  }

  ngOnInit() {
    this.getFavorites();
    this.getSittersForJobAssigning();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.jobId) {
      this.getFavorites();
      this.getSittersForJobAssigning();
    }

  }


  private getFavorites() {

    if (this.familyId) {
      this.getFavoriteSitters(this.familyId);
    }

    if (this.sitterId) {
      this.getFavoriteFamilies(this.sitterId);
    }
  }

  /**
   * Marker: Favorite Sitters
   * =============================================================================================
   */

  private getFavoriteSitters(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.familiesService.getFavoriteSitters(params)
      .subscribe((response: GetFavoriteSittersResponse) => {
          this.onGetFavoriteSittersSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetFavoriteSittersFailed(error);
        });
  }

  private onGetFavoriteSittersSuccess(response: GetFavoriteSittersResponse) {

    if (response.success) {
      this.favoriteSitters = response.favoriteSitters;
    } else {
      console.error('got error: ' + response.message);
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

  }

  private onGetFavoriteSittersFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Favorite Families
   * =============================================================================================
   */

  private getFavoriteFamilies(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};
    this.sittersService.getSitterFavoritFamilies(params)
      .subscribe((response: GetFavoriteFamiliesResponse) => {
          this.onGetFavoriteFamiliesSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetFavoriteFamiliesFailed(error);
        });
  }

  private onGetFavoriteFamiliesSuccess(response: GetFavoriteFamiliesResponse) {

    if (response.success) {
      this.favoriteFamilies = response.favoriteFamilies;
    } else {
      console.error('got error: ' + response.message);
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

  }

  private onGetFavoriteFamiliesFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Sitters for Job Assigning
   * =============================================================================================
   */

  private getSittersForJobAssigning() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetSittersForAssigningRequest = {
      familyId: this.familyId,
      jobDate: this.jobDate,
      jobStartTime: this.jobStartTime,
      jobEndTime: this.jobEndTime

    };
    this.searchString = this.searchString.trim();
    if (this.searchString !== '') {
      if (this.checkSearchString()) {
        params.dayName = this.searchString;
        this.sittersService.searchSittersForJobAssigning(params)
          .subscribe((response: GetSitersForAssigningResponse) => {
              this.onSearchSittersForJobAssigningSuccess(response);
            },
            (error: HttpErrorResponse) => {
              this.onGetSittersForJobAssigningFailed(error);
            });
      } else {
        this.sittersService.getSittersForJobAssigning(params)
          .subscribe((response: GetSitersForAssigningResponse) => {
              this.onGetSittersForJobAssigningSuccess(response);
            },
            (error: HttpErrorResponse) => {
              this.onGetSittersForJobAssigningFailed(error);
            });
      }
    } else {
      this.sittersService.getSittersForJobAssigning(params)
        .subscribe((response: GetSitersForAssigningResponse) => {
            this.onGetSittersForJobAssigningSuccess(response);
          },
          (error: HttpErrorResponse) => {
            this.onGetSittersForJobAssigningFailed(error);
          });
    }
  }

  private onGetSittersForJobAssigningSuccess(response: GetSitersForAssigningResponse) {

    if (response.success) {
      this.sittersForJob = [];
      this.totalNumberOfSitters = response.totalNumber;
      this.getSittersFromResponse(response.availableSitters, 'available');
      this.getSittersFromResponse(response.possibleAvailableSitters, 'questionable');
      this.getSittersFromResponse(response.unavailableSitters, 'unavailable');
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

  }

  private onSearchSittersForJobAssigningSuccess(response) {
    if (response.success) {
      this.sittersForJob = [];
      this.totalNumberOfSitters = response.totalNumber;
      this.getSittersFromResponse(response.sitters, 'available');
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetSittersForJobAssigningFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  private getSittersFromResponse(sourceArray: Array<BaseSitterInfo>, sitterAvailability: string) {
    for (let i = 0; i < sourceArray.length; i++) {
      this.sittersForJob.push({'sitterInfo': sourceArray[i], 'sitterAvailability': sitterAvailability});
    }
  }

  private checkSearchString() {
    if (dayNames.indexOf(this.searchString.charAt(0).toUpperCase() + this.searchString.substring(1).toLowerCase()) !== -1) {
      this.wrongDayNameFlag = false;
      return true;
    } else {
      this.wrongDayNameFlag = true;
    }
    return false;
  }

  private selectSitter(sitterId: string) {
    $('.sitters-for-assigning p').each(function () {
      if ($(this).attr('id') == sitterId && !$(this).hasClass('selected')) {
        $(this).addClass('selected');
      } else if ($(this).hasClass('selected')) {
        $(this).removeClass('selected');
      }
    });
  }

  private changeAssigningButtonLocal(sitterId: number, sitterName: string) {
    this.selectedSitterId = sitterId;
    this.selectedSitterName = sitterName;
    this.changeAssigningButton.emit([sitterId, sitterName]);
  }

  private refreshSitterList() {
    this.selectSitter(this.selectedSitterId.toString());
    this.changeAssigningButtonLocal(this.selectedSitterId, this.selectedSitterName);
  }

}
