import {Injectable} from '@angular/core';
import {ChangeFavoriteStatusRequest} from '../interfaces/requests/ChangeFavoriteStatusRequest';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class FavoritesService {

  constructor(private http: HttpClient) {}

  public addToFavorites(data: ChangeFavoriteStatusRequest): Observable<any> {
    return this.http.post(EndpointService.addToFavorites, data, AuthService.setHeader());
  }

  public removeFromFavorites(data: ChangeFavoriteStatusRequest): Observable<any> {
    return this.http.post(EndpointService.removeFromFavorites, data, AuthService.setHeader());
  }

}
