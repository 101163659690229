import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FamiliesService} from '../../../core/services/families.service';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {HttpErrorResponse} from '@angular/common/http';
import {CompleteFamilyInfo} from '../../../core/interfaces/models/CompleteFamilyInfo';
import {UserService} from '../../../core/services/user.service';
import {GetByIdRequest} from '../../../core/interfaces/requests/GetByIdRequest';
import {
  checkValidAvailableDate,
  checkValidAvailableTime,
  dayNames,
  monthNames,
  noWhitespaceValidator
} from '../../../core/shared/utils';
import {GetFamilyDetailsResponse} from '../../../core/interfaces/responses/GetFamilyDetailsResponse';
import {SetUserStatusRequest} from '../../../core/interfaces/requests/SetUserStatusRequest';
import {GetFamilyJobsResponse} from '../../../core/interfaces/responses/GetFamilyJobsResponse';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbCalendar, NgbDateStruct, NgbModal, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {AddJobRequest} from '../../../core/interfaces/requests/AddJobRequest';
import * as $ from 'jquery';
import {DateTimeHelper} from '../../../helpers/date-time-helper';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';


@Component({
  selector: 'app-family-details',
  templateUrl: './family-details.component.html',
  styleUrls: ['./family-details.component.scss']
})
export class FamilyDetailsComponent implements OnInit {

  protected familyId: number;
  protected familyDetails: CompleteFamilyInfo;
  protected familyUpcomingBookings: Array<JobInfo>;
  protected familyCompletedBookings: Array<JobInfo>;
  protected familyCanceledBookings: Array<number>;
  protected calendar = [];
  protected addJobForm: FormGroup;
  protected jobRepeatFlag: boolean;
  protected jobIsWeekFlag: boolean;
  protected startDate: NgbDateStruct;
  protected endDate: NgbDateStruct;
  protected minDateValue: object;

  protected dateTimeHelper: DateTimeHelper;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private jobCalendar: NgbCalendar, private formBuilder: FormBuilder, private route: ActivatedRoute,
              private userService: UserService, private familiesService: FamiliesService,
              private modalService: NgbModal, private ngbDateParserFormatter: NgbDateParserFormatter) {

    this.dateTimeHelper = new DateTimeHelper();

    this.familyUpcomingBookings = new Array<JobInfo>();
    this.familyCompletedBookings = new Array<JobInfo>();
    this.familyCanceledBookings = new Array<number>();
    this.familyDetails = null;
    this.jobRepeatFlag = false;
    this.jobIsWeekFlag = false;
    const date = new Date();
    this.minDateValue = {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()};
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.familyId = params['id'];
      this.getFamilyDetails(this.familyId);
      this.getFamilyJobs(this.familyId);

    });
    this.initAddJobForm();
    this.startDate = this.setDefaultDate();
    this.onSelectDate(this.startDate);
    this.endDate = this.setDefaultDate();
    this.onSelectDate(this.endDate);
  }


  initAddJobForm() {
    this.addJobForm = this.formBuilder.group({
      jobChildrens: ['', [Validators.required]],
      jobAddress: ['', [Validators.required]],
      jobCity: ['', [Validators.required]],
      jobState: ['', [Validators.required]],
      jobCountry: ['', [Validators.required]],
      jobZipCode: ['', [Validators.required]],
      jobStartDate: [this.jobCalendar.getToday(), [Validators.required]],
      jobEndDate: [this.jobCalendar.getToday(), [Validators.required]],
      jobStartTime: [{hour: 12, minute: 0}, [Validators.required]],
      jobEndTime: [{hour: 13, minute: 0}, [Validators.required]],
      jobIsRepetitive: ['false', [Validators.required, noWhitespaceValidator]],
      jobRepeatOption: ['every', [Validators.required, noWhitespaceValidator]],
      jobRepeatType: ['day', [Validators.required, noWhitespaceValidator]],
      jobRepeatDay: ['Monday', [Validators.required, noWhitespaceValidator]],
      jobNotesToSitter: ['', []],
      jobRequestForOperator: ['', []]
    }, {
      validator: [
        checkValidAvailableTime('jobStartTime', 'jobEndTime'),
        checkValidAvailableDate('jobStartDate', 'jobEndDate', 'jobIsRepetitive'),
      ]
    });
  }

  /**
   * Marker: Details
   * =============================================================================================
   */

  private getFamilyDetails(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.familiesService.getFamilyDetails(params)
      .subscribe((response: GetFamilyDetailsResponse) => {
          this.onGetFamilyDetailsSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetFamilyDetailsFailed(error);
        });
  }

  private onGetFamilyDetailsSuccess(response: GetFamilyDetailsResponse) {
    this.familyDetails = response.family;
    for (let i = 0; i < this.familyDetails.children.length; i++) {
      this.familyDetails.children[i].zoomed = true;
    }
    this.addJobForm.controls['jobAddress'].setValue(response.family.address);
    this.addJobForm.controls['jobCity'].setValue(response.family.city);
    this.addJobForm.controls['jobState'].setValue(response.family.state);
    this.addJobForm.controls['jobCountry'].setValue(response.family.country);
    this.addJobForm.controls['jobZipCode'].setValue(response.family.zipCode);
    this.familyDetails.activeUser = response.activeUser;

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetFamilyDetailsFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Children Component Events
   * =============================================================================================
   */

  protected refreshFamilyDetails() {
    this.getFamilyDetails(this.familyId);
  }


  /**
   * Marker: Status
   * =============================================================================================
   */

  private setFamilyStatus() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.familyDetails.activeUser = !this.familyDetails.activeUser;

    const params: SetUserStatusRequest = {
      userId: this.familyId,
      activeUser: this.familyDetails.activeUser
    };

    this.userService.setUserStatus(params)
      .subscribe(response => {
        LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
      });
  }

  /**
   * Marker: Jobs
   * =============================================================================================
   */


  private getFamilyJobs(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetByIdRequest = {id: id};

    this.familiesService.getFamilyJobs(params)
      .subscribe((response: GetFamilyJobsResponse) => {
        this.onGetFamilyJobsSuccess(response);
      }, (error) => {
        this.onGetFamilyJobsFailed(error);
      });
  }

  private onGetFamilyJobsSuccess(response: GetFamilyJobsResponse) {
    if (response.success) {
      this.familyCompletedBookings = response.completedJobs;
      this.familyUpcomingBookings = response.upcomingJobs;
      this.familyCanceledBookings = response.familyCanceledJobs;
      this.constructCalendar();
    } else {
      console.error('got error: ' + response.message);
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onGetFamilyJobsFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private addJob() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    if (this.addJobForm.valid) {
      const dateDefault = new Date();
      const startDate = new Date(Date.UTC(this.addJobForm.controls['jobStartDate'].value.year, (this.addJobForm.controls['jobStartDate'].value.month - 1), this.addJobForm.controls['jobStartDate'].value.day));
      let endDate = new Date(Date.UTC(this.addJobForm.controls['jobEndDate'].value.year, (this.addJobForm.controls['jobEndDate'].value.month - 1), this.addJobForm.controls['jobEndDate'].value.day));

      if (this.addJobForm.controls['jobIsRepetitive'].value === 'false') {
        endDate = startDate;
      }
      const childrenIds = [];
      for (let i = 0; i < this.familyDetails.children.length; i++) {
        if (this.familyDetails.children[i].zoomed) {
          childrenIds.push(this.familyDetails.children[i].childId);
        }
      }

      const isRepetitiveJob = (this.addJobForm.controls['jobIsRepetitive'].value === 'true');

      const params: AddJobRequest = {

        familyId: this.familyId,
        requestDate: new Date(),
        childrenIds: childrenIds,
        job: {
          startDate: (this.addJobForm.controls['jobStartDate'].value === null) ? dateDefault : startDate,
          endDate: (this.addJobForm.controls['jobEndDate'].value === null) ? dateDefault : endDate,
          startTime: this.addJobForm.controls['jobStartTime'].value.hour + ':' + this.addJobForm.controls['jobStartTime'].value.minute,
          endTime: this.addJobForm.controls['jobEndTime'].value.hour + ':' + this.addJobForm.controls['jobEndTime'].value.minute,
          isRepetitive: isRepetitiveJob,
          repeatOption: isRepetitiveJob === true ? this.addJobForm.controls['jobRepeatOption'].value : null,
          repeatType: isRepetitiveJob === true ? this.addJobForm.controls['jobRepeatType'].value : null,
          repeatDay: isRepetitiveJob === true ? this.addJobForm.controls['jobRepeatDay'].value : null,
          address: this.addJobForm.controls['jobAddress'].value,
          city: this.addJobForm.controls['jobCity'].value,
          state: this.addJobForm.controls['jobState'].value,
          country: this.addJobForm.controls['jobCountry'].value,
          zipCode: this.addJobForm.controls['jobZipCode'].value,
          notesToSitter: this.addJobForm.controls['jobNotesToSitter'].value,
          requestForOperator: this.addJobForm.controls['jobRequestForOperator'].value
        }
      };

      if (params.job.repeatType === 'day') {
        params.job.repeatDay = null;
      }
      this.familiesService.addJob(params)
        .subscribe((response: BaseResponse) => {
            this.onAddJobSuccess(response);
          },
          (error: HttpErrorResponse) => {
            this.onAddJobFailed(error);
          });
    }

  }

  private onAddJobSuccess(response: BaseResponse) {
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    if (response.success) {
      this.clearAddJob();
    } else {
      alert(response.message);
    }
  }

  private onAddJobFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  checkValidChildrens() {
    let checkValid = false;
    if (this.familyDetails) {
      for (let i = 0; i < this.familyDetails.children.length; i++) {
        if (this.familyDetails.children[i].zoomed) {
          checkValid = true;
          break;
        }
      }
    }
    if (checkValid) {
      this.addJobForm.controls['jobChildrens'].setErrors(null);
    } else {
      this.addJobForm.controls['jobChildrens'].setErrors({wrongValueMultipleCheckbox: true});
    }
  }

  public toDate(timestamp) {
    const d = new Date(timestamp);
    return monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
  }

  public getMonthName(timestamp) {
    const d = new Date(timestamp);
    return monthNames[d.getMonth()];
  }

  public getDayName(timestamp) {
    const d = new Date(timestamp);
    return d.getDate() + ' ' + dayNames[d.getDay()];
  }


  constructCalendar() {
    const currentYear = new Date().getFullYear();

    for (let i = 0; i < 12; i++) {

      let numberOfWeeks = 0;

      this.calendar[i] = {};
      this.calendar[i].weeks = [];
      this.calendar[i].monthName = monthNames[i];
      this.calendar[i].monthNumber = i;
      this.calendar[i].weeks[numberOfWeeks] = FamilyDetailsComponent.getPreliminaryWeek();

      for (let j = 1; j <= 31; j++) {

        const day = new Date(currentYear, i, j);

        // Javascript returns the next month if you create a date object with a day that isn't in that month
        // (new Date(2018, 2, 30) returns March second instead of Feb 30 or throwing an error)
        if (day.getMonth() !== i) {
          break;
        }

        this.calendar[i].weeks[numberOfWeeks][day.getDay()] = {
          day: day.getDate()
          // type: SitterDetailsComponent.getDayType(),
        };

        // If the day is the last in a week, add a new week to the month
        if (day.getDay() === 6) {
          numberOfWeeks++;
          this.calendar[i].weeks[numberOfWeeks] = FamilyDetailsComponent.getPreliminaryWeek();
        }
      }
    }

  }

  // TODO: improve this
  static getPreliminaryWeek() {
    const obj = {
      day: -1,
      type: '',
    };
    return [obj, obj, obj, obj, obj, obj, obj];
  }

  getDayType(selectedDay, selectedMonth) {
    let className = '';
    if (this.familyCompletedBookings !== null) {
      this.familyCompletedBookings.forEach(function (element) {
        let d = new Date(element.date);
        d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
        if (d.getDate() === selectedDay && d.getMonth() === selectedMonth) {
          className = 'completed';
        }
      });
    }
    if (this.familyUpcomingBookings !== null) {
      this.familyUpcomingBookings.forEach(function (element) {
        let d = new Date(element.date);
        d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
        if (d.getDate() === selectedDay && d.getMonth() === selectedMonth) {
          if (className === 'completed') {
            className = className + '-upcoming';
          } else if (className === '') {
            className = 'upcoming';
          }
        }
      });
    }
    if (this.familyCanceledBookings !== null) {
      this.familyCanceledBookings.forEach(function (element) {
        let d = new Date(element);
        d = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
        if (d.getDate() === selectedDay && d.getMonth() === selectedMonth) {
          if (className === 'completed-upcoming' || className === 'upcoming') {
            className = className + '-canceled';
          } else if (className === '') {
            className = 'canceled';
          }
        }
      });
    }

    return className;
  }


  clearAddJob() {
    this.jobRepeatFlag = false;
    this.jobIsWeekFlag = false;
    for (let i = 0; i < this.familyDetails.children.length; i++) {
      this.familyDetails.children[i].zoomed = true;
    }
    this.addJobForm = this.formBuilder.group({
      jobChildrens: ['', [Validators.required]],
      jobAddress: [this.familyDetails.address, [Validators.required]],
      jobCity: [this.familyDetails.city, [Validators.required]],
      jobState: [this.familyDetails.state, [Validators.required]],
      jobCountry: [this.familyDetails.country, [Validators.required]],
      jobZipCode: [this.familyDetails.zipCode, [Validators.required]],
      jobStartDate: [this.jobCalendar.getToday(), [Validators.required]],
      jobEndDate: [this.jobCalendar.getToday(), [Validators.required]],
      jobStartTime: [{hour: 12, minute: 0}, [Validators.required]],
      jobEndTime: [{hour: 13, minute: 0}, [Validators.required]],
      jobIsRepetitive: ['false', [Validators.required, noWhitespaceValidator]],
      jobRepeatOption: ['every', [Validators.required, noWhitespaceValidator]],
      jobRepeatType: ['day', [Validators.required, noWhitespaceValidator]],
      jobRepeatDay: ['Monday', [Validators.required, noWhitespaceValidator]],
      jobNotesToSitter: ['', []],
      jobRequestForOperator: ['', []]
    }, {
      validator: [
        checkValidAvailableTime('jobStartTime', 'jobEndTime'),
        checkValidAvailableDate('jobStartDate', 'jobEndDate', 'jobIsRepetitive'),
      ]
    });
  }

  toggleActive($event, index: number) {
    $event.stopPropagation();
    this.familyDetails.children[index].zoomed = !this.familyDetails.children[index].zoomed;
    this.checkValidChildrens();
  }

  onSelectDate(date: NgbDateStruct) {
    if (date != null) {
      this.startDate = date;
      this.endDate = date; //  needed for first time around due to ngModel not binding during ngOnInit call. Seems like a bug in ng2.

    }
  }

  setDefaultDate(): NgbDateStruct {
    const startDate = new Date();
    const startYear = startDate.getFullYear().toString();
    const startMonth = startDate.getMonth() - 1;
    const startDay = '1';

    return this.ngbDateParserFormatter.parse(startYear + '-' + startMonth.toString() + '-' + startDay);
  }

}
