import {Component, OnInit} from '@angular/core';
import {SittersService} from '../../../core/services/sitters.service';
import {AppComponent} from '../../../app.component';
import {Router} from '@angular/router';
import {AuthService} from '../../../core/services/auth.service';
import {BaseSitterInfo} from '../../../core/interfaces/models/BaseSitterInfo';
import {GetSittersResponse} from '../../../core/interfaces/responses/GetSittersResponse';
import {SortSittersRequest} from '../../../core/interfaces/requests/SortSittersRequest';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';


@Component({
  selector: 'app-sitters',
  templateUrl: './sitters.component.html',
  styleUrls: ['./sitters.component.scss']
})

export class SittersComponent implements OnInit {

  private sitters: Array<BaseSitterInfo> = new Array<BaseSitterInfo>();

  protected searchString: string;
  protected sortBy: string;

  private sitterServiceOptions: SortSittersRequest;

  protected totalNumberOfSitters: number;
  protected  RESULTS_PER_PAGE = 20;
  protected PAGE_NUMBER = 0;

  protected showMoreButtonDisplayed: boolean;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private sittersService: SittersService,
              private router: Router, private auth: AuthService, private app: AppComponent,
              private modalService: NgbModal) {

    this.searchString = '';
    this.initSortCriteria();
  }

  ngOnInit() {
    this.getSittersByCriteria();
  }

  private initSortCriteria() {

    this.sortBy = 'firstName';

    this.sitterServiceOptions = {
      searchString: '',
      pageNumber: this.PAGE_NUMBER,
      resultsPerPage: this.RESULTS_PER_PAGE,
      sortBy: this.sortBy
    };
  }

  /**
   * Marker: Retrieve Sitters
   * =============================================================================================
   */

  private getSitters() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.sittersService.getSitters(this.sitterServiceOptions).subscribe(
      (response: GetSittersResponse) => {
        this.onGetSittersSuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onGetSittersFailed(error);
      }
    );
  }

  private onGetSittersSuccess(response: GetSittersResponse) {
    if (response.success) {
      this.sitters = response.sitters;
      this.totalNumberOfSitters = response.totalNumber;

      this.displayShowMoreIfNeeded();

      LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    }
  }

  private onGetSittersFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private displayShowMoreIfNeeded() {

    if (this.PAGE_NUMBER === 0) {
      this.showMoreButtonDisplayed = this.RESULTS_PER_PAGE < this.totalNumberOfSitters;
    } else {
      this.showMoreButtonDisplayed = this.RESULTS_PER_PAGE * this.PAGE_NUMBER < this.totalNumberOfSitters;
    }
  }

  /**
   * Marker: Search Sitters
   * =============================================================================================
   */

  private searchSitter() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.sittersService.searchSitters(this.sitterServiceOptions)
      .subscribe((response: GetSittersResponse) => {
          this.onSearchSitterSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onSearchSitterFailed(error);
        });
  }

  private onSearchSitterSuccess(response: GetSittersResponse) {
    this.sitters = response.sitters;
    this.totalNumberOfSitters = response.totalNumber;

    this.displayShowMoreIfNeeded();

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onSearchSitterFailed(error: HttpErrorResponse) {
    console.log(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Sort Sitters
   * =============================================================================================
   */

  getSittersByCriteria() {

    this.sitterServiceOptions.sortBy = this.sortBy;
    this.sitterServiceOptions.searchString = this.searchString;
    this.searchString.trim();

    if (this.searchString) {
      this.searchSitter();
    } else {
      this.getSitters();
    }

  }

  private getMoreSiters() {
    this.PAGE_NUMBER ++;
    this.sitterServiceOptions.resultsPerPage = this.RESULTS_PER_PAGE * this.PAGE_NUMBER;
    this.getSitters();
  }

}
