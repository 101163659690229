import {Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {checkMatchingPasswords, noWhitespaceValidator} from '../../../core/shared/utils';
import {emailValidator, nameValidator, passwordValidator, phoneValidator} from '../../../helpers/regex';
import {SittersService} from '../../../core/services/sitters.service';
import {ERROR_MESSAGES} from '../../../helpers/errors';
import {AddSitterRequest} from '../../../core/interfaces/requests/AddSitterRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-add-sitters',
  templateUrl: './add-sitters.component.html',
  styleUrls: ['./add-sitters.component.scss']
})
export class AddSittersComponent implements OnInit {

  @Output() duplicateEmailError = new EventEmitter();

  protected errorMessages = ERROR_MESSAGES;
  protected receivedErrorMessage: string;

  protected sitterForm: FormGroup;
  protected viewPassword: boolean;
  protected viewPasswordConfirm: boolean;
  protected datepickerMaxDate: Date;

  protected isAddingUser: boolean;
  protected userAdded: boolean;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private formBuilder: FormBuilder, private sittersService: SittersService, private elementRef: ElementRef) { }

  ngOnInit() {
    this.viewPassword = false;
    this.viewPasswordConfirm = false;
    this.datepickerMaxDate = new Date();
    this.initFlags();
    this.initSitterForm();
  }

  private initFlags() {
    this.isAddingUser = false;
    this.userAdded = false;
  }

  private initSitterForm() {

    this.sitterForm = this.formBuilder.group({
      firstName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      lastName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      email: ['', [Validators.required, emailValidator, noWhitespaceValidator]],
      password: ['', [Validators.required, passwordValidator, noWhitespaceValidator]],
      repeatPassword: ['', [Validators.required, passwordValidator, noWhitespaceValidator]],
      phoneNumber: ['', [Validators.required, phoneValidator, noWhitespaceValidator]],
      permAddress: ['', [Validators.required, noWhitespaceValidator]],
      permCity: ['', [Validators.required, noWhitespaceValidator]],
      permState: ['', [Validators.required, noWhitespaceValidator]],
      permCountry: ['', [Validators.required, noWhitespaceValidator]],
      permZipCode: ['', [Validators.required, noWhitespaceValidator]],
      currentAddress: ['', [Validators.required, noWhitespaceValidator]],
      currentCity: ['', [Validators.required, noWhitespaceValidator]],
      currentState: ['', [Validators.required, noWhitespaceValidator]],
      currentCountry: ['', [Validators.required, noWhitespaceValidator]],
      currentZipCode: ['', [Validators.required, noWhitespaceValidator]],
      emergencyContactName: ['', [Validators.required, nameValidator]],
      emergencyContactRelationship: ['', [Validators.required, nameValidator]],
      emergencyContactPhone: ['', [Validators.required, phoneValidator]],
      birthdate: ['', [Validators.required]],
      about: [''],
      emailText: ['', []]
    }, {
      validator: checkMatchingPasswords('password', 'repeatPassword')
    });

  }

  /**
   * Marker: Add Sitter
   * =============================================================================================
   */

  private getNewSitter(): AddSitterRequest {

    let birthdate = this.sitterForm.controls['birthdate'].value;
    birthdate = new Date(birthdate);
    birthdate = new Date(Date.UTC(birthdate.getFullYear(), birthdate.getMonth(), birthdate.getDate()));

    const params: AddSitterRequest = {
      byOperator: sessionStorage.getItem('operatorEmail'),
      sitter: {
        user: {
          firstName: this.sitterForm.controls['firstName'].value,
          lastName: this.sitterForm.controls['lastName'].value,
          email: this.sitterForm.controls['email'].value,
          password: this.sitterForm.controls['password'].value,
          phoneNumber: this.sitterForm.controls['phoneNumber'].value,
        },
        permAddress: this.sitterForm.controls['permAddress'].value,
        permCity: this.sitterForm.controls['permCity'].value,
        permState: this.sitterForm.controls['permState'].value,
        permCountry: this.sitterForm.controls['permCountry'].value,
        permZipCode: this.sitterForm.controls['permZipCode'].value,
        currentAddress: this.sitterForm.controls['currentAddress'].value,
        currentCity: this.sitterForm.controls['currentCity'].value,
        currentState: this.sitterForm.controls['currentState'].value,
        currentCountry: this.sitterForm.controls['currentCountry'].value,
        currentZipCode: this.sitterForm.controls['currentZipCode'].value,
        birthdate: birthdate,
        occupation: '',
        about: this.sitterForm.controls['about'].value,
        emergencyContactName: this.sitterForm.controls['emergencyContactName'].value,
        emergencyContactRelationship: this.sitterForm.controls['emergencyContactRelationship'].value,
        emergencyContactPhone: this.sitterForm.controls['emergencyContactPhone'].value
      },
      emailText: this.sitterForm.controls['emailText'].value
    };

    return params;
  }


  private createSitter() {

    this.isAddingUser = true;

    this.sittersService.addSitter(this.getNewSitter()).subscribe(
      (response: BaseResponse) => {
        this.onAddSitterSuccess(response);
      },
      (error: HttpErrorResponse) => {
        this.onAddSitterFailed(error);
      }
    );
  }

  private onAddSitterSuccess(response: BaseResponse) {

    if (response.success) {
      this.userAdded = true;
    } else {
      this.userAdded = false;
      this.receivedErrorMessage = response.message;
      if (this.receivedErrorMessage === ERROR_MESSAGES.DUPLICATE_EMAIL_ERROR_MESSAGE) {
        // this.elementRef.nativeElement.querySelector('#email').scrollIntoView();
        this.duplicateEmailError.emit();
      }
    }

    this.isAddingUser = false;

    //   switch (response.message) {
    //     case ERROR_MESSAGES.DUPLICATE_EMAIL_ERROR_MESSAGE:
    //       this.receivedErrorMessage = this.errorMessages.DUPLICATE_EMAIL_ERROR_MESSAGE;
    //       this.elementRef.nativeElement.querySelector('#email').scrollIntoView();
    //       break;
    //     default:
    //       this.receivedErrorMessage = ERROR_MESSAGES.GENERIC_ERROR_MESSAGE;
    //       break;
    //   }


  }

  private onAddSitterFailed(error: HttpErrorResponse) {
    console.log(error);
    this.userAdded = false;
    this.isAddingUser = false;
  }



}
