import {Component, Input, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

import {AppComponent} from '../../../app.component';
import {AuthService} from '../../../core/services/auth.service';

import {AdminGuard} from '../../../core/guards/admin.guard';
import {OperatorGuard} from '../../../core/guards/operator.guard';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() role: string;

  protected canActivate: boolean;
  protected userEmail: string;
  protected franchiseCity: string;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private router: Router, private app: AppComponent, private adminGuard: AdminGuard, private operatorGuard: OperatorGuard,
              private auth: AuthService) {

    this.canActivate = false;

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.role = this.auth.getUserRole();
        this.canActivate = (this.role === 'ROLE_ADMIN') || (this.role === 'ROLE_OPERATOR');
      }
    });
  }

  ngOnInit() {
    this.role = this.auth.getUserRole();
    this.userEmail = this.auth.getUserEmail();
    this.franchiseCity = this.auth.getFranchiseCity();
    this.canActivate = (this.role === 'ROLE_ADMIN') || (this.role === 'ROLE_OPERATOR');
  }

  /**
   * Marker: Logout
   * =============================================================================================
   */

  private logout() {
    this.auth.logout();
    this.role = null;
    this.app.setLoggedIn(null);
    this.router.navigate(['/login']);
  }

}
