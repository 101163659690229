import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import {dayNames, monthNames} from '../../../core/shared/utils';
import {FavoritesService} from '../../../core/services/favorites.service';
import {ChangeFavoriteStatusRequest} from '../../../core/interfaces/requests/ChangeFavoriteStatusRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss']
})


export class JobsListComponent implements OnInit, OnChanges {

  @Input() jobsArray: Array<JobInfo>;
  @Input() sitterId: number;
  @Input() familyId: number;
  @Input() jobsType: string;
  @Input() emptyListMessage: string;

  title: string;


  /**
   * Marker: Jobs
   * =============================================================================================
   */

  constructor(private favoritesService: FavoritesService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.setComponentTitle();
  }

  ngOnChanges() {
  }

  private setComponentTitle() {
    if (this.sitterId) {
      if (this.jobsType === 'upcoming') {
        this.title = 'UPCOMING JOBS';
      } else {
        this.title = 'COMPLETED JOBS';
      }
    } else if (this.familyId) {
      if (this.jobsType === 'upcoming') {
        this.title = 'UPCOMING BOOKINGS';
      } else {
        this.title = 'COMPLETED BOOKINGS';
      }
    }
  }


  protected addToFavorites(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const data = this.getFavoriteData(id);

    this.favoritesService.addToFavorites(data)
      .subscribe((response: BaseResponse) => {
          this.onChangeFavoriteStatusSuccess(id, true, response);
        },
        (error: HttpErrorResponse) => {
          this.onChangeFavoriteStatusFailed(error);
        });
  }

  protected removeFromFavorites(id: number) {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const data = this.getFavoriteData(id);
    this.favoritesService.removeFromFavorites(data)
      .subscribe((response: BaseResponse) => {
          this.onChangeFavoriteStatusSuccess(id, false, response);
        },
        (error: HttpErrorResponse) => {
          this.onChangeFavoriteStatusFailed(error);
        });
  }

  private getFavoriteData(id: number): ChangeFavoriteStatusRequest {

    let data: ChangeFavoriteStatusRequest;
    if (this.familyId) {
      data = {
        favoriteType: 0,
        familyId: this.familyId,
        sitterId: id
      };
    } else {
      data = {
        favoriteType: 1,
        familyId: id,
        sitterId: this.sitterId,
      };
    }

    return data;
  }

  private onChangeFavoriteStatusSuccess(id: number, status: boolean, response: BaseResponse) {
    if (response.success === true) {
      const changedIndexes = this.getJobIndexesForId(id);
      for (let i = 0; i < changedIndexes.length; i++) {
        this.jobsArray[changedIndexes[i]].favorite = status;
      }
    }

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }


  private onChangeFavoriteStatusFailed(error: HttpErrorResponse) {
    console.error('change favorite status error: ' + error.message);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private getJobIndexesForId(id: number): Array<number> {
    let indexes: Array<number> = new Array<number>();

    if (this.familyId) {
      for (let i = 0; i < this.jobsArray.length; i++) {
        if (id === this.jobsArray[i].sitterId) {
          indexes.push(i);
        }
      }

      return indexes;
    } else {
      for (let i = 0; i < this.jobsArray.length; i++) {
        if (id === this.jobsArray[i].familyId) {
          indexes.push(i);
        }
      }

      return indexes;
    }
  }

  public getMonthName(timestamp) {
    const d = new Date(timestamp);
    return monthNames[d.getMonth()];
  }

  public getDayName(timestamp) {
    const d = new Date(timestamp);
    return d.getDate() + ' ' + dayNames[d.getDay()];
  }


}
