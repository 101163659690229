import {Component, NgZone, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {HttpErrorResponse} from '@angular/common/http';
import {GetAllJobsRequest} from '../../../core/interfaces/requests/GetAllJobsRequest';
import {DashboardService} from '../../../core/services/dashboard.service';
import {GetAllJobsResponse} from '../../../core/interfaces/responses/GetAllJobsResponse';
import {JobInfo} from '../../../core/interfaces/models/JobInfo';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  protected compressed: number; // default ; 0 full size left menu; 1 closed left menu; 2 submenu opeed; 3 details page opened
  protected openedSubmenu: string;
  protected allJobs: GetAllJobsResponse;
  protected submenuList: Array<string>;
  protected selectedJob: JobInfo;
  protected sidebarCollapsedFlag: boolean;
  protected submenuOpenedFlag: boolean;
  protected monthCalendarFlag: boolean;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private dashboardService: DashboardService, private zone: NgZone,
              private modalService: NgbModal) {
    this.sidebarCollapsedFlag = false;
    this.submenuOpenedFlag = false;
    this.monthCalendarFlag = true;
  }

  ngOnInit() {
    this.submenuList = ['upcomingSubmenu', 'unassignedSubmenu', 'pendingSubmenu', 'declinedSubmenu', 'cancelledSubmenu'];
    this.openedSubmenu = '';
    this.getAllJobs('Jan');
  }

  /**
   * Marker: Get All Jobs
   * =============================================================================================
   */

  private getAllJobs(monthName: string) {
    console.log('inside get all jobs');

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: GetAllJobsRequest = {
      calendarType: 0,
      monthName: monthName
    };

    this.dashboardService.getAllJobs(params)
      .subscribe((response: GetAllJobsResponse) => {
          this.onGetAllJobsSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onGetAllJobsFailed(error);
        });
  }

  private onGetAllJobsSuccess(response: GetAllJobsResponse) {
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    console.log('all josb succcesss hide spinner');
    this.zone.run(() => {
      this.allJobs = response;
    });
  }

  private onGetAllJobsFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Helpers
   * =============================================================================================
   */

  removeSubmenuOpenedClass() {
    for (let i = 0; i < this.submenuList.length; i++) {
      if ($('.' + this.submenuList[i]).hasClass('submenu-opened-item')) {
        $('.' + this.submenuList[i]).toggleClass('submenu-opened-item'); // remove class for right white border
      }
    }
  }

  sidebarCollapse() {
    // console.log('collapse');
    // console.log('Initial');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
    if (this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // submenu opened with month calendar
      this.submenuOpenedFlag = false;
      this.sidebarCollapsedFlag = false;
      this.removeSubmenuOpenedClass();
      $('.sidebar').toggleClass('submenu-opened'); // remove white border to left menu
      $('.under-nav').toggleClass('submenu-opened'); // remove white border under left menu
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      // decompress sidebar
      if ($('#sidebar').hasClass('col-sm-1')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
      $('#sidebar-submenu').hide(); // hide submenu
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // collapsed sidebar
      this.sidebarCollapsedFlag = false;
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      // decompress sidebar
      if ($('#sidebar').hasClass('col-sm-1')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
      // change col size for left calendar
      if ($('#calendar-wrapper').hasClass('col-sm-11')) {
        $('#calendar-wrapper').toggleClass('col-sm-9').toggleClass('col-sm-11');
        $('#calendar-wrapper').toggleClass('col-md-9').toggleClass('col-md-11');
        $('#calendar-wrapper').toggleClass('col-lg-9').toggleClass('col-lg-11');
      }
    } else if (this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // full size sidebar with month calendar
      this.sidebarCollapsedFlag = true;
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      // compress sidebar
      if ($('#sidebar').hasClass('col-sm-3')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
      // change col size for left calendar
      if ($('#calendar-wrapper').hasClass('col-sm-9')) {
        $('#calendar-wrapper').toggleClass('col-sm-9').toggleClass('col-sm-11');
        $('#calendar-wrapper').toggleClass('col-md-9').toggleClass('col-md-11');
        $('#calendar-wrapper').toggleClass('col-lg-9').toggleClass('col-lg-11');
      }
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with submenu opened
      $('#sidebar-submenu').show(); // show submenu
      // change col size for details page
      if ($('#details-page').hasClass('col-sm-8')) {
        $('#details-page').toggleClass('col-sm-8').toggleClass('col-sm-6');
        $('#details-page').toggleClass('col-md-8').toggleClass('col-md-6');
        $('#details-page').toggleClass('col-lg-8').toggleClass('col-lg-6');
      }
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with collapsed sidebar and no submenu opened
      this.sidebarCollapsedFlag = false;
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      // decompress sidebar
      if ($('#sidebar').hasClass('col-sm-1')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
      // change col size for details page
      if ($('#details-page').hasClass('col-sm-8')) {
        $('#details-page').toggleClass('col-sm-8').toggleClass('col-sm-6');
        $('#details-page').toggleClass('col-md-8').toggleClass('col-md-6');
        $('#details-page').toggleClass('col-lg-8').toggleClass('col-lg-6');
      }
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // details page with full sidebar and no submenu opened
      this.sidebarCollapsedFlag = true;
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      // compress sidebar
      if ($('#sidebar').hasClass('col-sm-3')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
      // change col size for details page
      if ($('#details-page').hasClass('col-sm-6')) {
        $('#details-page').toggleClass('col-sm-8').toggleClass('col-sm-6');
        $('#details-page').toggleClass('col-md-8').toggleClass('col-md-6');
        $('#details-page').toggleClass('col-lg-8').toggleClass('col-lg-6');
      }
    }
    // console.log('After');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
  }

  openSubmenu(imageId: string) {
    // console.log('open submenu');
    this.openedSubmenu = imageId;
    this.removeSubmenuOpenedClass();
    $('.' + imageId).toggleClass('submenu-opened-item'); // remove white border on selected submenu
    // console.log('Initial');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
    if (this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // submenu opened with month calendar then open the other submenu
      if ($('#details-page').hasClass('col-sm-6')) {
        $('#details-page').toggleClass('col-sm-8').toggleClass('col-sm-6');
        $('#details-page').toggleClass('col-md-8').toggleClass('col-md-6');
        $('#details-page').toggleClass('col-lg-8').toggleClass('col-lg-6');
      }
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // collapsed sidebar
      this.submenuOpenedFlag = true;
      $('#sidebar-submenu').show(); // show submenu
      $('.sidebar').toggleClass('submenu-opened'); // add white border to left menu
      $('.under-nav').toggleClass('submenu-opened'); // add white border under left menu
      // change col size for left calendar
      $('#calendar-wrapper').toggleClass('col-sm-9').toggleClass('col-sm-11');
      $('#calendar-wrapper').toggleClass('col-md-9').toggleClass('col-md-11');
      $('#calendar-wrapper').toggleClass('col-lg-9').toggleClass('col-lg-11');
    } else if (this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // full size sidebar with month calendar
      this.sidebarCollapsedFlag = true;
      this.submenuOpenedFlag = true;
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      $('.sidebar').toggleClass('submenu-opened'); // add white border to left menu
      $('.under-nav').toggleClass('submenu-opened'); // add white border under left menu
      // compress sidebar
      if ($('#sidebar').hasClass('col-sm-3')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
      $('#sidebar-submenu').show();
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with submenu opened then open the other submenu
      if ($('#details-page').hasClass('col-sm-8')) {
        $('#details-page').toggleClass('col-sm-8').toggleClass('col-sm-6');
        $('#details-page').toggleClass('col-md-8').toggleClass('col-md-6');
        $('#details-page').toggleClass('col-lg-8').toggleClass('col-lg-6');
      }
      $('#sidebar-submenu').show(); // show submenu
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with collapsed sidebar and no submenu opened
      this.submenuOpenedFlag = true;
      $('.sidebar').toggleClass('submenu-opened'); // add white border to left menu
      $('.under-nav').toggleClass('submenu-opened'); // add white border under left menu
      // change col size for details page
      if ($('#details-page').hasClass('col-sm-8')) {
        $('#details-page').toggleClass('col-sm-8').toggleClass('col-sm-6');
        $('#details-page').toggleClass('col-md-8').toggleClass('col-md-6');
        $('#details-page').toggleClass('col-lg-8').toggleClass('col-lg-6');
      }
      $('#sidebar-submenu').show(); // show submenu
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // details page with full sidebar and no submenu opened
      this.sidebarCollapsedFlag = true;
      this.submenuOpenedFlag = true;
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      $('.sidebar').toggleClass('submenu-opened'); // add white border to left menu
      $('.under-nav').toggleClass('submenu-opened'); // add white border under left menu
      // compress sidebar
      if ($('#sidebar').hasClass('col-sm-3')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
      $('#sidebar-submenu').show(); // show submenu
    }
    // console.log('After');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
  }

  closeSubmenu(imageId: string) {
    // console.log('close submenu');
    this.openedSubmenu = '';
    // console.log('Initial');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
    this.removeSubmenuOpenedClass();
    this.submenuOpenedFlag = false;
    this.sidebarCollapsedFlag = false;
    $('#sidebar-submenu').hide(); // hide submenu
    $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
    $('#sidebar').toggleClass('active'); // collapse sidebar
    $('.sidebar').toggleClass('submenu-opened'); // remove white border to left menu
    $('.under-nav').toggleClass('submenu-opened'); // remove white border under left menu
    // decompress sidebar
    if ($('#sidebar').hasClass('col-sm-1')) {
      $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
      $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
      $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
    }
    // console.log('After');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
  }

  openDetailsPage(jobId: number, jobStatus: string, jobDate: Date) {
    this.selectedJob = this.getJobWithIdAndStatus(jobId, jobStatus, jobDate);

    // console.log('open details page');
    // console.log('Initial');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
    if (this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // submenu opened with month calendar
      this.monthCalendarFlag = false;
      $('#calendar-wrapper').hide(); // hide calendar
      $('#sidebar-submenu').hide(); // hide submenu
      $('#details-page').show(); // show details page
      $('#day-calendar-wrapper').show(); // show day calendar
      // change col size for details page
      if ($('#details-page').hasClass('col-sm-6')) {
        $('#details-page').toggleClass('col-sm-8').toggleClass('col-sm-6');
        $('#details-page').toggleClass('col-md-8').toggleClass('col-md-6');
        $('#details-page').toggleClass('col-lg-8').toggleClass('col-lg-6');
      }
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // collapsed sidebar
      this.monthCalendarFlag = false;
      $('#sidebar-submenu').show(); // show submenu
      $('#calendar-wrapper').hide(); // hide calendar
      $('#sidebar-submenu').hide(); // hide submenu
      $('#details-page').show(); // show details page
      $('#day-calendar-wrapper').show(); // show day calendar
    } else if (this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // full size sidebar with month calendar
      this.sidebarCollapsedFlag = true;
      this.monthCalendarFlag = false;
      $('#sidebarCollapse').toggleClass('spinEffect'); // spin on collapse button
      $('#sidebar').toggleClass('active'); // collapse sidebar
      $('#calendar-wrapper').hide(); // hide calendar
      $('#details-page').show(); // show details page
      $('#day-calendar-wrapper').show(); // show day calendar
      // compress sidebar
      if ($('#sidebar').hasClass('col-sm-3')) {
        $('#sidebar').toggleClass('col-sm-3').toggleClass('col-sm-1');
        $('#sidebar').toggleClass('col-md-3').toggleClass('col-md-1');
        $('#sidebar').toggleClass('col-lg-3').toggleClass('col-lg-1');
      }
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with submenu opened then open the other details page
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with collapsed sidebar and no submenu opened
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // details page with full sidebar and no submenu opened
    }
    // console.log('After');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
  }

  closeDetailsPage() {
    // console.log('close details page');
    // console.log('Initial');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
    $('#calendar-wrapper').show(); // show calendar
    $('#details-page').hide(); // hide details page
    $('#day-calendar-wrapper').hide(); // hide day calendar
    if (this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // submenu opened with month calendar
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // collapsed sidebar with month calendar
    } else if (this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // full size sidebar with month calendar
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with submenu opened
      $('#sidebar-submenu').show(); // show submenu
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && this.sidebarCollapsedFlag) {
      // details page with collapsed sidebar and no submenu opened
      if ($('#calendar-wrapper').hasClass('col-sm-9')) {
        $('#calendar-wrapper').toggleClass('col-sm-9').toggleClass('col-sm-11');
        $('#calendar-wrapper').toggleClass('col-md-9').toggleClass('col-md-11');
        $('#calendar-wrapper').toggleClass('col-lg-9').toggleClass('col-lg-11');
      }
    } else if (!this.monthCalendarFlag && this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // no such case
    } else if (!this.monthCalendarFlag && !this.submenuOpenedFlag && !this.sidebarCollapsedFlag) {
      // details page with full sidebar and no submenu opened
    }
    this.monthCalendarFlag = true;

    // console.log('After');
    // console.log('monthCalendarFlag: ');
    // console.log(this.monthCalendarFlag);
    // console.log('submenuOpenedFlag: ');
    // console.log(this.submenuOpenedFlag);
    // console.log('sidebarCollapsedFlag: ');
    // console.log(this.sidebarCollapsedFlag);
  }

  getJobWithIdAndStatus(jobId: number, jobStatus: string, jobDate: Date): JobInfo {

    let jobInfo: JobInfo;
    let jobsWithStatus: Array<JobInfo>;

    switch (jobStatus) {
      case 'upcoming':
        jobsWithStatus = this.allJobs.upcomingJobs;
        break;
      case 'unassigned':
        jobsWithStatus = this.allJobs.unassignedJobs;
        break;
      case 'pending':
        jobsWithStatus = this.allJobs.pendingJobs;
        break;
      case 'declined':
        jobsWithStatus = this.allJobs.declinedJobs;
        break;
      case 'canceled':
        jobsWithStatus = this.allJobs.canceledJobs;
        break;
      case 'completed':
        jobsWithStatus = this.allJobs.completedJobs;
        break;
    }

    for (let index = 0; index < jobsWithStatus.length; index++) {
      if ((jobId === jobsWithStatus[index].jobId || jobId === jobsWithStatus[index].jobId) && jobDate === jobsWithStatus[index].date) {
        jobInfo = jobsWithStatus[index];
      }
    }

    return jobInfo;
  }

  changeCalendar(filterType: string) {
  }
}
