import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FamilyChild} from '../../../core/interfaces/models/FamilyChild';
import {EmergencyContact} from '../../../core/interfaces/models/EmergencyContact';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  dayValidator,
  monthValidator,
  nameValidator,
  numberValidator,
  optionalStringValidator,
  phoneValidator, yearValidator
} from '../../../helpers/regex';
import {checkValidAvailableDate, checkValidAvailableTime, noWhitespaceValidator} from '../../../core/shared/utils';
import {HttpErrorResponse} from '@angular/common/http';
import {AddFamilyChildRequest} from '../../../core/interfaces/requests/AddFamilyChildRequest';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {AddEmergencyContactRequest} from '../../../core/interfaces/requests/AddEmergencyContactRequest';
import {EditAditionalDetailsRequest} from '../../../core/interfaces/requests/EditAditionalDetailsRequest';
import {FamiliesService} from '../../../core/services/families.service';
import {CompleteFamilyInfo} from '../../../core/interfaces/models/CompleteFamilyInfo';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import {DateTimeHelper} from '../../../helpers/date-time-helper';

@Component({
  selector: 'app-family-info',
  templateUrl: './family-info.component.html',
  styleUrls: ['./family-info.component.scss']
})
export class FamilyInfoComponent implements OnInit {

  @Input() familyDetails: CompleteFamilyInfo;
  @Input() children: Array<FamilyChild>;
  @Input() editingAllowed: boolean;

  @Output() refreshPageEvent = new EventEmitter();

  protected selectedFamilyChild: FamilyChild;
  protected selectedEmergencyContact: EmergencyContact;
  protected editFamilyNotesFlag: boolean;
  protected editHouseRulesFlag: boolean;
  protected editMedicalNotesFlag: boolean;
  protected familyNote: string;
  protected houseRule: string;
  protected medicalNote: string;
  protected detailType: number;
  protected childForm: FormGroup;
  protected emergencyContactForm: FormGroup;

  protected currentYear: number;

  private dateTimeHelper: DateTimeHelper;

  constructor(private formBuilder: FormBuilder, private familiesService: FamiliesService,
              private modalService: NgbModal) {
    this.editFamilyNotesFlag = false;
    this.editHouseRulesFlag = false;
    this.editMedicalNotesFlag = false;
    this.familyNote = null;
    this.houseRule = null;
    this.medicalNote = null;
    this.detailType = null;
    this.dateTimeHelper = new DateTimeHelper();
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {

    this.initChildForm();
    this.initEmergencyContactForm();

  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.familyDetails) {
  //     this.childForm.controls['childLastName'].setValue(changes.familyDetails.currentValue.familyDetails.familyName);
  //   }
  // }

  initChildForm() {

    this.childForm = this.formBuilder.group({
      childGender: ['', [Validators.required]],
      childFirstName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      childLastName: [this.familyDetails == null ? '' : this.familyDetails.familyName, [Validators.required, nameValidator, noWhitespaceValidator]],
      childBdayMonth: ['', [Validators.required, monthValidator]],
      childBdayDay: ['', [Validators.required, dayValidator]],
      childBdayYear: ['', [Validators.required, yearValidator]],
      childWeight: ['', [Validators.required, numberValidator]],
      childBedTime: [{hour: 21, minute: 0}, []],
      childMealTime: [{hour: 18, minute: 0}, []],
      childAllergens: ['', []],
      childGames: ['', []],
      childNotes: ['', []]
    }, {
      validator: [
        checkValidAvailableTime('childBedTime', 'childBedTime'),
        checkValidAvailableTime('childMealTime', 'childMealTime')
      ]
    });

    if (this.editingAllowed === false) {
      this.childForm.disable();
    }
  }

  initEmergencyContactForm() {

    this.emergencyContactForm = this.formBuilder.group({
      emergencyContactType: ['', [Validators.required]],
      emergencyContactName: ['', [Validators.required, nameValidator, noWhitespaceValidator]],
      emergencyContactRelationship: ['', [optionalStringValidator, nameValidator]],
      emergencyContactPhone: ['', [Validators.required, phoneValidator, noWhitespaceValidator]],
      emergencyContactAddress: [''],
      emergencyContactCity: [''],
      emergencyContactState: [''],
      emergencyContactCountry: [''],
      emergencyContactZipCode: ['']
    });
  }


  addFamilyChild() {
    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const birthdate = new Date(Date.UTC(this.childForm.controls['childBdayYear'].value, (this.childForm.controls['childBdayMonth'].value - 1), this.childForm.controls['childBdayDay'].value));

    const params: AddFamilyChildRequest = {
      familyId: this.familyDetails.familyId,
      firstName: this.childForm.controls['childFirstName'].value,
      lastName: this.childForm.controls['childLastName'].value,
      gender: this.childForm.controls['childGender'].value,
      birthdate: birthdate,
      weight: this.childForm.controls['childWeight'].value,
      mealTime: this.childForm.controls['childMealTime'].value.hour + ':' + this.childForm.controls['childMealTime'].value.minute,
      bedTime: this.childForm.controls['childBedTime'].value.hour + ':' + this.childForm.controls['childBedTime'].value.minute,
      allergens: this.childForm.controls['childAllergens'].value.split(','),
      games: this.childForm.controls['childGames'].value.split(','),
      notes: this.childForm.controls['childNotes'].value
    };

    if (this.selectedFamilyChild) {
      params.childId = this.selectedFamilyChild.childId;
    } else {
      params.childId = null;
    }
    this.familiesService.addFamilyChild(params)
      .subscribe((response: BaseResponse) => {
          this.onAddFamilyChildSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onAddFamilyChildFailed(error);
        });
  }

  private onAddFamilyChildSuccess(response: BaseResponse) {
    // this.getFamilyDetails(this.familyId);
    this.refreshPageEvent.emit();
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onAddFamilyChildFailed(error: HttpErrorResponse) {
    console.error(error);
    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  addEmergencyContact() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    const params: AddEmergencyContactRequest = {

      familyId: this.familyDetails.familyId,
      type: this.emergencyContactForm.controls['emergencyContactType'].value,
      name: this.emergencyContactForm.controls['emergencyContactName'].value,
      relationship: this.emergencyContactForm.controls['emergencyContactRelationship'].value,
      phoneNumber: this.emergencyContactForm.controls['emergencyContactPhone'].value,
      address: this.emergencyContactForm.controls['emergencyContactAddress'].value,
      city: this.emergencyContactForm.controls['emergencyContactCity'].value,
      state: this.emergencyContactForm.controls['emergencyContactState'].value,
      country: this.emergencyContactForm.controls['emergencyContactCountry'].value,
      zipCode: this.emergencyContactForm.controls['emergencyContactZipCode'].value

    };

    if (this.selectedEmergencyContact) {
      params.contactId = this.selectedEmergencyContact.contactId;
    } else {
      params.contactId = null;
    }
    this.familiesService.addEmergencyContact(params)
      .subscribe((response: BaseResponse) => {
          this.onAddEmergencyContactSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onAddEmergencyContactFailed(error);
        });
  }

  private onAddEmergencyContactSuccess(response: BaseResponse) {
    // this.getFamilyDetails(this.familyId);
    this.refreshPageEvent.emit();

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onAddEmergencyContactFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  editAdditionalDetails() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    let detailText = '';
    if (this.detailType === 0) {
      detailText = this.familyNote;
    } else if (this.detailType === 1) {
      detailText = this.houseRule;
    } else if (this.detailType === 2) {
      detailText = this.medicalNote;
    }
    const params: EditAditionalDetailsRequest = {
      familyId: this.familyDetails.familyId,
      detailType: this.detailType,
      detailText: detailText
    };
    this.familiesService.editAdditionalDetails(params)
      .subscribe((response: BaseResponse) => {
          this.onEditAdditionalDetailsSuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onEditAdditionalDetailsFailed(error);
        });
  }

  private onEditAdditionalDetailsSuccess(response: BaseResponse) {
    // this.getFamilyDetails(this.familyId);
    this.refreshPageEvent.emit();

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private onEditAdditionalDetailsFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }


  setChildValues() {
    if (this.selectedFamilyChild) {
      const mealTime = this.dateTimeHelper.timeConvertor(this.selectedFamilyChild.mealTime.toString());
      const bedTime = this.dateTimeHelper.timeConvertor(this.selectedFamilyChild.bedTime.toString());

      let birthdate = new Date(this.selectedFamilyChild.birthdate);
      birthdate = new Date(birthdate.valueOf() + birthdate.getTimezoneOffset() * 60000);
      this.childForm.controls['childGender'].setValue(this.selectedFamilyChild.gender.toString());
      this.childForm.controls['childGames'].setValue((this.selectedFamilyChild.games) ? this.selectedFamilyChild.games.toString() : '');
      this.childForm.controls['childAllergens'].setValue((this.selectedFamilyChild.allergens) ? this.selectedFamilyChild.allergens.toString() : '');
      this.childForm.controls['childNotes'].setValue(this.selectedFamilyChild.notes.toString());
      this.childForm.controls['childMealTime'].setValue({
        hour: mealTime[0],
        minute: mealTime[1]
      });
      this.childForm.controls['childBedTime'].setValue({
        hour: bedTime[0],
        minute: bedTime[1]
      });
      this.childForm.controls['childWeight'].setValue(this.selectedFamilyChild.weight.toString());
      this.childForm.controls['childLastName'].setValue(this.selectedFamilyChild.lastName.toString());
      this.childForm.controls['childFirstName'].setValue(this.selectedFamilyChild.firstName.toString());
      this.childForm.controls['childBdayYear'].setValue('' + birthdate.getFullYear());
      this.childForm.controls['childBdayMonth'].setValue('' + (birthdate.getMonth() + 1));
      this.childForm.controls['childBdayDay'].setValue(birthdate.getDate());
    } else {
      this.initChildForm();
    }
  }

  setEmergencyContactValues() {
    if (this.selectedEmergencyContact) {
      this.emergencyContactForm.controls['emergencyContactType'].setValue(this.selectedEmergencyContact.type);
      this.emergencyContactForm.controls['emergencyContactName'].setValue(this.selectedEmergencyContact.name);
      this.emergencyContactForm.controls['emergencyContactRelationship'].setValue(this.selectedEmergencyContact.relationship);
      this.emergencyContactForm.controls['emergencyContactPhone'].setValue(this.selectedEmergencyContact.phoneNumber);
      this.emergencyContactForm.controls['emergencyContactAddress'].setValue(this.selectedEmergencyContact.address);
      this.emergencyContactForm.controls['emergencyContactCity'].setValue(this.selectedEmergencyContact.city);
      this.emergencyContactForm.controls['emergencyContactState'].setValue(this.selectedEmergencyContact.state);
      this.emergencyContactForm.controls['emergencyContactCountry'].setValue(this.selectedEmergencyContact.country);
      this.emergencyContactForm.controls['emergencyContactZipCode'].setValue(this.selectedEmergencyContact.zipCode);
    } else {
      this.initEmergencyContactForm();
    }
  }

}
