import {Component, Input, OnInit, Output, SimpleChanges, EventEmitter} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-day-item',
  templateUrl: './day-item.component.html',
  styleUrls: ['./day-item.component.scss']
})

export class DayItemComponent implements OnInit {

  @Input() day;
  @Input() listType;
  @Output() openDetailsPageLocal = new EventEmitter();
  protected filterDisabledOptions: Array<string>;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor() {
  }

  ngOnInit() {
    $('input:checkbox:not(:checked)').each(function () {
      $('.' + $(this).val()).addClass('hidden-job');
    });
  }

  openDetailsPage(jobId: number, jobStatus: string, jobDate: Date) {

    if (jobId !== 0) {
      this.openDetailsPageLocal.emit([jobId, jobStatus, jobDate]);
    }

  }
}
