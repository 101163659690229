import {Injectable, Injector} from '@angular/core';
import {AuthService} from '../core/services/auth.service';
import {AppComponent} from '../app.component';
import {EMPTY, Observable, throwError} from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/internal/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerHelper} from './loading-spinner-helper';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router, private inj: Injector, private authService: AuthService, private app: AppComponent,
              private modalService: NgbModal) {
    console.warn('interceptor loaded');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(response => {
      console.error(response);
      if (!(response instanceof HttpErrorResponse)) {
        return throwError(response);
      }

      if (request.url.indexOf('logout') > -1) {
        this.authService.logout();
        this.router.navigate(['/login']);
        LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
        return EMPTY;
      }


      if (response && response.error && (response.error.message.indexOf('Expired') != -1 ||
        response.error.message.indexOf('Access Denied') != -1)) {

        if (request.url.indexOf('login') > -1) {
          return throwError(response);
        }

        console.log('token expired');
        this.app.setLoggedIn(null);
        this.authService.logout();
        this.router.navigate(['/login']);
        LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
        return EMPTY;
      }

      if (response.status !== 401) {
        return throwError(response);
      }

    }));
  }

  public get http(): HttpClient {
    return this.inj.get(HttpClient);
  }
}
