import {Validators} from '@angular/forms';

export const nameValidator = Validators.pattern('^([a-zA-Z\u0080-\u024F]+(?:. |-| |\'))*[a-zA-Z\u0080-\u024F]*$');

export const optionalStringValidator = Validators.pattern('');

export const emailValidator = Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))'
  + '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

// export const phoneValidator = Validators.pattern('((?:\\+|00)[17](?: |\\-)?|(?:\\+|00)[1-9]\\d{0,2}(?: |\\-)?|(?:\\+|00)1\\-\\d{3}(?: |\\-)?)?(0\\d|\\([0-9]{3}\\)|[1-9]{0,3})(?:((?: |\\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\\-)[0-9]{3}(?: |\\-)[0-9]{4})|([0-9]{7}))');

export const phoneValidator = Validators.pattern('^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}|[0-9]{3}[0-9]{3}[0-9]{4}$')
export const passwordValidator = Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

export const monthValidator = Validators.pattern( '^(0?[1-9]|1[012])$');

export const dayValidator = Validators.pattern('^(?:[1-9]|[12][0-9]|3[01])$');

export const numberValidator = Validators.pattern('^[0-9]*$');

export const yearValidator = Validators.pattern('^(19|20)\\d{2}$');
