import {Component, OnInit} from '@angular/core';
import {FamiliesService} from '../../../core/services/families.service';
import {AppComponent} from '../../../app.component';
import {Router} from '@angular/router';
import {AuthService} from '../../../core/services/auth.service';
import {BaseFamilyInfo} from '../../../core/interfaces/models/BaseFamilyInfo';
import {GetFamiliesResponse} from '../../../core/interfaces/responses/GetFamiliesResponse';
import {SortFamiliesRequest} from '../../../core/interfaces/requests/SortFamiliesRequest';
import {HttpErrorResponse} from '@angular/common/http';
import {LoadingSpinnerHelper} from '../../../helpers/loading-spinner-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-families',
  templateUrl: './families.component.html',
  styleUrls: ['./families.component.scss']
})

export class FamiliesComponent implements OnInit {

  private families: Array<BaseFamilyInfo> = new Array<BaseFamilyInfo>();

  protected searchString: string;

  private familyServiceOptions: SortFamiliesRequest;

  protected totalNumberOfFamilies: number;
  protected RESULTS_PER_PAGE = 20;
  protected PAGE_NUMBER = 0;

  protected showMoreButtonDisplayed: boolean;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private familiesService: FamiliesService,
              private router: Router, private auth: AuthService, private app: AppComponent,
              private modalService: NgbModal) {

    this.searchString = '';
    this.initSortCriteria();
  }

  ngOnInit() {
    this.getFamiliesByCriteria();
  }

  private initSortCriteria() {

    this.familyServiceOptions = {
      searchString: '',
      pageNumber: this.PAGE_NUMBER,
      resultsPerPage: this.RESULTS_PER_PAGE
    };
  }

  /**
   * Marker: Retrieve Families
   * =============================================================================================
   */

  private getFamilies() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.familiesService.getFamilies(this.familyServiceOptions).subscribe(
      (response: GetFamiliesResponse) => {
        this.onGetFamiliesSuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onGetFamiliesFailed(error);
      }
    );
  }

  private onGetFamiliesSuccess(response: GetFamiliesResponse) {
    if (response.success) {
      this.families = response.families;
      this.totalNumberOfFamilies = response.totalNumber;
      this.displayShowMoreIfNeeded();

      LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
    }
  }

  private onGetFamiliesFailed(error: HttpErrorResponse) {
    console.error(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  private displayShowMoreIfNeeded() {

    if (this.PAGE_NUMBER === 0) {
      this.showMoreButtonDisplayed = this.RESULTS_PER_PAGE < this.totalNumberOfFamilies;
    } else {
      this.showMoreButtonDisplayed = this.RESULTS_PER_PAGE * this.PAGE_NUMBER < this.totalNumberOfFamilies;
    }
  }


  /**
   * Marker: Search Families
   * =============================================================================================
   */

  private searchFamily() {

    LoadingSpinnerHelper.showLoadingSpinner(this.modalService);

    this.familiesService.searchFamilies(this.familyServiceOptions)
      .subscribe((response: GetFamiliesResponse) => {
          this.onSearchFamilySuccess(response);
        },
        (error: HttpErrorResponse) => {
          this.onSearchFamilyFailed(error);
        });
  }

  private onSearchFamilySuccess(response: GetFamiliesResponse) {
    this.families = response.families;
    this.totalNumberOfFamilies = response.totalNumber;

    this.displayShowMoreIfNeeded();

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);

  }

  private onSearchFamilyFailed(error: HttpErrorResponse) {
    console.log(error);

    LoadingSpinnerHelper.hideLoadingSpinner(this.modalService);
  }

  /**
   * Marker: Sort Families
   * =============================================================================================
   */

  getFamiliesByCriteria() {

    this.familyServiceOptions.searchString = this.searchString;
    this.searchString.trim();

    if (this.searchString) {
      this.searchFamily();
    } else {
      this.getFamilies();
    }

  }

  private getMoreFamilies() {
    this.PAGE_NUMBER++;
    this.familyServiceOptions.resultsPerPage = this.RESULTS_PER_PAGE * this.PAGE_NUMBER;
    this.getFamilies();
  }

}
