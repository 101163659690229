import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FamiliesService {

  constructor(private http: HttpClient) { }

  addFamily(data): Observable<any> {
    return this.http.post(EndpointService.addFamilyUrl, data, AuthService.setHeader());
  }

  getFamilies(data): Observable<any> {
    return this.http.post(EndpointService.getFamilies, data, AuthService.setHeader());
  }

  searchFamilies(data): Observable<any> {
    return this.http.post(EndpointService.searchFamilies, data, AuthService.setHeader());
  }

  getFamilyDetails(data): Observable<any> {
    return this.http.post(EndpointService.getFamilyById, data, AuthService.setHeader());
  }

  getFamilyNotes(data): Observable<any> {
    return this.http.post(EndpointService.getFamilyNotes, data, AuthService.setHeader());
  }

  addFamilyNote(data): Observable<any> {
    return this.http.post(EndpointService.addFamilyNote, data, AuthService.setHeader());
  }

  getFamilyJobs(data): Observable<any> {
    return this.http.post(EndpointService.getFamilyJobs, data, AuthService.setHeader());
  }

  addJob(data): Observable<any> {
    return this.http.post(EndpointService.addJob, data, AuthService.setHeader());
  }

  addFamilyChild(data): Observable<any> {
    return this.http.post(EndpointService.addFamilyChild, data, AuthService.setHeader());
  }

  addEmergencyContact(data): Observable<any> {
    return this.http.post(EndpointService.addEmergencyContact, data, AuthService.setHeader());
  }

  getFavoriteSitters(data): Observable<any> {
    return this.http.post(EndpointService.getFavoriteSitters, data, AuthService.setHeader());
  }

  editAdditionalDetails(data): Observable<any> {
    return this.http.post(EndpointService.editAdditionalDetails, data, AuthService.setHeader());
  }

  getFamiliesForBilling(): Observable<any> {
    return this.http.get(EndpointService.getFamiliesForBilling, AuthService.setHeader());
  }

  getFeesForFranchise(data): Observable<any> {
    return this.http.post(EndpointService.getFeesForFranchise, data, AuthService.setHeader());
  }

  getPaymentsForFamily(data): Observable<any> {
    return this.http.post(EndpointService.getPaymentsForFamily, data, AuthService.setHeader());
  }

  getBillsForFamily(data): Observable<any> {
    return this.http.post(EndpointService.getBillsForFamily, data, AuthService.setHeader());
  }

  addBill(data): Observable<any> {
    return this.http.post(EndpointService.addBill, data, AuthService.setHeader());
  }

}
