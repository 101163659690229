import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';


import {AppComponent} from '../../../app.component';
import {AuthService} from '../../../core/services/auth.service';
import {emailValidator} from '../../../helpers/regex';
import {BaseResponse} from '../../../core/interfaces/responses/BaseResponse';
import {LoginRequest} from '../../../core/interfaces/requests/LoginRequest';
import {HttpErrorResponse} from '@angular/common/http';
import {ForgotPasswordRequest} from '../../../core/interfaces/requests/ForgotPasswordRequest';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  protected loginForm: FormGroup;
  protected loginError;
  protected passwordResp: string;

  /**
   * Marker: Lifecycle
   * =============================================================================================
   */

  constructor(private router: Router, private app: AppComponent, private auth: AuthService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, emailValidator]],
      password: ['', Validators.required]
    });
    this.passwordResp = '';
    this.checkUserRole();
  }

  /**
   * Marker: Session
   * =============================================================================================
   */

  private checkUserRole() {

    const token = AuthService.getSessionToken();

    if (token) {
      if (this.auth.getUserRole() === 'ROLE_ADMIN') {
        this.router.navigate(['/franchises']);
      } else if (this.auth.getUserRole() === 'ROLE_OPERATOR') {
        this.router.navigate(['/dashboard']);
      } else {
        this.loginError = 'Invalid username or password';
        this.auth.logout();
      }
    }
  }


  /**
   * Marker: Login
   * =============================================================================================
   */

  logIn() {

    const params: LoginRequest = {
      email: this.loginForm.controls['email'].value.trim(),
      password: this.loginForm.controls['password'].value
    };

    this.auth.login(params)
      .subscribe((response: BaseResponse) => {
        this.onLoginSuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onLoginFailed(error);
      });
  }

  private onLoginSuccess(response: BaseResponse) {

    if (response.success) {

      this.loginError = null;
      this.auth.setVariables(response);
      const role = this.auth.getUserRole();
      this.app.setLoggedIn(role);
      this.loginError = false;

      this.checkUserRole();

    } else {
      this.loginError = response.message;
    }
  }

  private onLoginFailed(error: HttpErrorResponse) {

    this.loginError = error.error.message;

  }

  /**
   * Marker: Forgot Password
   * =============================================================================================
   */

  showResetForm() {
    $('#password').hide();
    $('#login').hide();
    $('#forgot-password').hide();
    $('#login-redirect').show();
    $('#reset').show();
  }

  hideResetForm() {
    $('#password').show();
    $('#login').show();
    $('#forgot-password').show();
    $('#login-redirect').hide();
    $('#reset').hide();
  }

  forgotPassword() {

    const params: ForgotPasswordRequest = {
      email: this.loginForm.controls['email'].value.trim()
    };

    this.auth.forgotPasword(params)
      .subscribe((response: BaseResponse) => {
        this.onForgotPasswordSuccess(response);
      }, (error: HttpErrorResponse) => {
        this.onForgotPasswordFailed(error);
      });
  }

  private onForgotPasswordSuccess(response: BaseResponse) {
    this.passwordResp = response.message;
    this.hideResetForm();
  }

  private onForgotPasswordFailed(error: HttpErrorResponse) {
    this.passwordResp = error.error.message;
  }

}
